import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import lao_logo from "../../images/Emblem_of_Laos.svg_.png"
import logo from "../../images/logo-nuol.png"

export default function PresentATemporaryStudentCard() {
	return (
		<div className="container" >
			<div className="card" >
				<div className="card-body" style={{
					opacity: 0.1,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: "25%",
					marginLeft: "35%",
					position: 'absolute'
				}}>
					<img src={logo} style={{ width: 258, height: 350 }} alt='logo' />
				</div>
				<div className="card-body" style={{ padding: 46 }}>
					<div className="d-flex justify-content-center">
						<div style={{ marginBottom: 22, marginTop: 16, marginLeft: -55 }}>
							<img src={lao_logo} style={{ width: 84, height: 74 }} alt='lao logo' />
						</div>
					</div>
					<div className="d-flex justify-content-between">
						<div className="card-profile"
							style={{
								flexDirection: "column",
								justifyContent: "space-around",
								display: "flex",
								alignItems: "center",
							}}>
							<div style={{ borderRadius: 110, backgroundColor: "#888888", paddingLeft: 20, paddingRight: 20, paddingTop: 15, paddingBottom: 15 }}>
								<FontAwesomeIcon icon={faUser} size="5x" color={"#ffff"} />
							</div>
						</div>
						<div className="text-center" style={{ fontSize: 18 }}>
							ສາ​ທາ​ລະ​ນະ​ລັດ ປະ​ຊາ​ທິ​ປະ​ໄຕ ປະ​ຊາ​ຊົນ​ລາວ <br />
							ສັນ​ຕິ​ພາບ ເອ​ກະ​ລາດ ປະ​ຊາ​ທິ​ປະ​ໄຕ ເອ​ກະ​ພາບ ວັດ​ທະ​ນາ​ຖາ​ວອນ
							<h3 style={{ color: "#6F6F6F", fontSize: 30, fontWeight: "bold", marginTop: 19 }}>
								ບັດ​ປ​ະ​ຈຳ​ຕົວນັກ​ສຶກ​ສາ​ຊົ່ວ​ຄາວ
							</h3>
						</div>

						<div
							style={{
								flexDirection: "column",
								justifyContent: "flex-end",
								display: "flex",
								// marginRight: 60,
								color: "#707070",
								fontWeight: "bold",
								fontSize: 20,
							}}>
							ເລກ​ທະ​ບຽນ:................
						</div>
					</div>
					<div style={{ fontSize: 18, marginTop: 21 }}>
						<table style={{ width: "100%" }} >
							<tr>
								<td><p><span className="text-bold">ຊື່: </span>............ວຽງສະຫວັນ........................
									<span className="text-bold"> ນາມສະກຸນ:</span>............ ວົງມີໄຊ ............
									<span className="text-bold">ວັນ, ​ເດືອນ, ​ປີ​ເກີດ: </span>.......... 09/03/1996 ..........</p></td>
							</tr>
							<tr>
								<td><p><span className="text-bold">ເກີດທີ່​ບ້ານ: </span> ............ ນາສົ້ມມໍ ............
									<span className="text-bold"> ເມືອງ​ເກີດ: </span>............ ເມືອງປາກຊັນ ..........
									<span className="text-bold">ແຂວງເກີດ: </span> .......... ບໍລິຄໍາໄຊ ..........</p></td>
							</tr>
							<tr>
								<td><p><span className="text-bold">ນັກ​ສຶກ​ສາ​ລະ​ດັບ: </span> ............</p></td>
							</tr>
							<tr>
								<td><p><span className="text-bold">ນັກ​ສຶກ​ສາ​ລະ​ບົບ: </span> ............</p></td>
							</tr>
							<tr>
								<td><p><span className="text-bold">ນັກ​ສຶກ​ສາ: </span> ...................</p></td>
							</tr>
							<tr>
								<td><p><span className="text-bold">ຄະ​ນ​ະ: </span> .......................
									<span className="text-bold"> ສາຂາວິຊາ: </span> ..........ສກຫດ່ສຫກ່ດສຫກດ່ຫກສ<span>..........</span></p></td>
							</tr>
						</table>
						<div className="d-flex justify-content-between" style={{ marginTop: 30 }}>
							<div className="div-box">
								<span className="text-bold">ໝາຍ​ເຫດ: </span> ກຳ​ນົດ​ໃຊ​້​ບັດ 90 ວັນ ນັບ​ແຕ່​ວັນ​ທີ່​ອອກ​ບັດ​ເປັນ​ຕົ້ນ​ໄປ</div>
							<div className="text-center">
								ມະ​ຫາ​ວິ​ທິ​ຍ​າ​ໄລ​ແຫ່ງ​ຊາດ, ວັນ​ທີ່ ...................
								<div>
									ຫົວ​ໜ້າ​ຫ້ອງ​ການ​ວິ​ຊາ​ການ
								</div>
							</div>
						</div>
					</div>
					{/* <div><img src={logo} className="img2" /></div> */}
				</div>
			</div>
		</div>
	)
}
