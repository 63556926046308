import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import { VERSION } from "../../consts";
import nuolimage from "../../images/nuol-image.jpeg";
import logo from "../../images/logo-nuol.png";
import jcb from "../../images/JCB.gif";
import Visa from "../../images/verified_Visa.png";
import mastercard from "../../images/mastercard.png";
import pngkit_call from "../../images/pngkit_call.png";
import safekey from "../../images/safekey.png";

import useReactRouter from "use-react-router";
import { BrowserView, MobileView } from "react-device-detect";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { Box, Button } from "@material-ui/core";
export default function Login() {
  const { history } = useReactRouter();
  const [showDanger, setShowDanger] = useState(false);

  const _handleClickLao = () => {
    history.push("/login-from");
  };
  const _handleClickEnglish = () => {
    history.push("/login-from-en");
  };
  return (
    <div>
      <BrowserView>
        <div style={{ display: "flex" }}>
          <div className="col-sm-6 cardCustom">
            <img src={nuolimage} className="img" alt="nuol logo" />
            <div className="topleft"></div>
            <div className="textdev">
              <img src={logo} className="logo" alt="logo" />
              <div className="textfont-size24 colorWhite">
                ມະຫາວິທະຍາໄລແຫ່ງຊາດ <br />
                National University of Laos
              </div>
              <div className="textfont-size50 colorWhite paddings">
                ຍິນ​ດີ​ຕ້ອນ​ຮັບ​ເຂົ້າ​ສູ່​ເວັບ​ໄຊ​ທ໌
                <br /> ການ​ລົງ​ທະ​ບຽນເຂົ້າຮຽນ
              </div>
            </div>
          </div>
          <div className="col-sm-6" style={{ background: "#F5F5F5" }}>
            <div
              style={{
                textAlign: "right",
                marginRight: 23,
                marginTop: 24,
                fontSize: 24,
              }}
            >
              ມີ​ບັນ​ຫາ​ເຂົ້າ​ສູ່​ລະ​ບົບ?
              <a href="#" onClick={() => setShowDanger(true)}>
                ຊ່ວຍ​ເຫຼືອ
              </a>
            </div>

            <div className="text-center">
              <div className="textfont-size30 text-bold">
                ເລືອກ​ຮູບ​ແບບ​ການ​ເຂົ້າ​ສູ່​ລະ​ບົບ
              </div>
              <div className="textfont-size20">
                ກະ​ລຸ​ນາ, ເລືອກ​ຮູບ​ແບບ​ການລ໋ອກ​ອິນ​ໃຫ້​ຖຶກ​ຕ້ອງ
              </div>
            </div>
            {/* ສໍາລັບນັກສືກສາໃນແຜນ */}

            {/* ສໍາລັບນັກສືກສາໃນແຜນ */}
            <div
              className="d-flex justify-content-center"
              onClick={() => _handleClickLao()}
            >
              <div className="login2" style={{ cursor: "pointer" }}>
                <div className="col-sm-10">
                  <div className="textfont-size26 colorWhite">
                    ລົງ​ທະ​ບຽນເຂົ້າຮຽນ
                  </div>
                  <div className="textfont-size30 text-bold colorWhite">
                    ສຳລັບ​ນັກ​ສຶກ​ສາລາວ
                  </div>
                </div>
                <div
                  style={{ textAlign: "right", marginTop: 20 }}
                  className="col-sm-1 colorWhite"
                >
                  <FontAwesomeIcon icon={faSignInAlt} size="3x" />
                </div>
              </div>
            </div>

            <div
              className="d-flex justify-content-center"
              onClick={() => _handleClickEnglish()}
            >
              <div style={{ cursor: "pointer" }} className="login1">
                <div className="col-sm-9">
                  <div className="textfont-size20 colorWhite">
                    Foreign Students/International Course
                  </div>
                  <div className="textfont-size24 text-bold colorWhite">
                    Registration Form
                  </div>
                </div>
                <div
                  style={{ textAlign: "right", marginTop: 20, marginLeft: 30 }}
                  className="col-sm-1 colorWhite"
                >
                  <FontAwesomeIcon icon={faSignInAlt} size="3x" />
                </div>
              </div>
            </div>
            <div className="text-center textfont-size18">
              ລິຂະສິດ
              <br /> Copyright © 2024 National University of Laos.
              <br /> All Rights Reserved.
              <br />
              <br />
            </div>

            <div className="text-center">
              <img
                src={mastercard}
                style={{ width: 50, height: 20, marginRight: 20 }}
                alt="mastercard"
              />
              <img
                src={Visa}
                style={{ width: 50, height: 20, marginRight: 20 }}
                alt="visa"
              />
              <img
                src={jcb}
                style={{ width: 40, height: 20, marginRight: 20 }}
                alt="jcb"
              />
              <img
                src={safekey}
                style={{ width: 80, height: 20 }}
                alt="safe key"
              />
              <br />
              <br />
              <p style={{ fontSize: 11 }}>{VERSION}</p>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div style={{ display: "flex" }}>
          <div className="col-sm-12 cardCustom">
            <img src={nuolimage} className="img" alt="logo" />
            <div className="topleft"></div>
            <div className="textdev">
              <img src={logo} style={{ width: 80 }} alt="logo" />
              <div style={{ fontSize: 12, color: "#fff" }}>
                ມະຫາວິທະຍາໄລແຫ່ງຊາດ <br />
                National University of Laos
              </div>
              <div style={{ fontSize: 16, paddingTop: 18, color: "#fff" }}>
                ຍິນ​ດີ​ຕ້ອນ​ຮັບ​ເຂົ້າ​ສູ່​ເວັບ​ໄຊ​ທ໌
                <br /> ການ​ລົງ​ທະ​ບຽນເຂົ້າຮຽນ
              </div>

              <div
                className="d-flex justify-content-center"
                onClick={() => _handleClickLao()}
              >
                <div
                  className="loginMobileViewOutPlan col-md-5 col-10"
                  style={{ cursor: "pointer" }}
                >
                  <div className="col-10">
                    <div className="textfont-size16 colorWhite">
                      ລົງ​ທະ​ບຽນເຂົ້າຮຽນ
                    </div>
                    <div className="textfont-size16 text-bold colorWhite">
                      ສຳລັບ​ນັກ​ສຶກ​ສາລາວ
                    </div>
                  </div>
                  <div
                    style={{ textAlign: "right" }}
                    className="col-2 colorWhite"
                  >
                    <FontAwesomeIcon icon={faSignInAlt} size="2x" />
                  </div>
                </div>
              </div>

              <div
                className="d-flex justify-content-center"
                onClick={() => _handleClickEnglish()}
              >
                <div
                  style={{ cursor: "pointer" }}
                  className="loginMobileViewInPlan col-md-5 col-10"
                >
                  <div className="col-10">
                    <div className="textfont-size14 colorWhite">
                      Foreign Students/International Course
                    </div>
                    <div className="textfont-size16 text-bold colorWhite">
                      Registration Form
                    </div>
                  </div>
                  <div
                    style={{ textAlign: "right" }}
                    className="col-2 colorWhite"
                  >
                    <FontAwesomeIcon icon={faSignInAlt} size="2x" />
                  </div>
                </div>
              </div>
              <div
                className="fixed-bottom mb-3"
                style={{ fontSize: 12, color: "#fff", textAlign: "center" }}
              >
                ລິຂະສິດ
                <br /> Copyright © 2024 National University of Laos.
                <br /> All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </MobileView>
      <Modal
        show={showDanger}
        onHide={() => setShowDanger(false)}
        animation={false}
      >
        <Modal.Header className="borderModalLogin">
          <div>ຕິດ​ຕໍ່​ພວກ​ເຮົາ</div>
        </Modal.Header>
        <Modal.Body
          style={{
            color: "#707070",
            textAlign: "center",
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          <p>ຊ່ອງ​ທາງ​ໃນ​ການຕິດ​ຕໍ່​​ພວກ​ເຮົາ​</p>
          <p>ເບີ​ໂທ​ລະ​ສັບ:</p>
          <p>021 771 989</p>
          <p>ວິຊາການ ມຊ:</p>
          <p>020 5445 6359</p>
          <p>020 5252 2266</p>
          <p>ອີ​ເມວ:</p>
          <p>info.01slms@gmail.com</p>
          <p>ໝາຍເຫດ: ຕິດຕໍ່ໄດ້ພາຍໃນໂມງລາດຊະການເທົ່ານັ້ນ </p>
          <img
            src={pngkit_call}
            style={{ width: 190, height: 121 }}
            alt="group call"
          />
          <div className="w-100 mt-3 mb-3 text-center">
            <a
              href={
                "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/folder/Preregistration-%E0%BA%84%E0%BA%B9%E0%BB%88%E0%BA%A1%E0%BA%B7.pdf"
              }
              download={"handbook-prere.pdff"}
              style={{ textDecoration: "none" }} // Removes underline from the link
            >
              <button
                style={{
                  backgroundColor: "#057cae",
                  color: "white",
                  border: "none",
                  padding: "10px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                className="btn"
              >
                ໂຫລດຄູ່ມືການນຳໃຊ້
              </button>
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
