import { gql } from "apollo-boost";

export const CREATE_PAYMENT = gql`
  mutation CreatePayment($data: paymentInput!) {
    createPayment(data: $data){
        id
    }
  }
`;
// export const PAYMENT = gql`
//   mutation CreatePayment($data: paymentInput!) {
//     createPayment(data: $data){
//         id
//     }
//   }
// `;