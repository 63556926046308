import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import styled from "styled-components";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import Login from "../page/login/login";
import FormLogin from "../page/login/form_login";
import FormLoginEn from "../page/login/form_login_en";
// Navbar
import CustomNavbar from "../common/customNavbar";
import RegistrationStudent from "../page/registration/RegistrationStudent";
import CardStudent from "../page/registration/CardStudent";
import RegistrationStudentInfo from "../page/registration/RegistrationStudentInfo";
import RegistrationStudentEn from "../page/registrationEn/RegistrationStudentEn";
import CardStudentEn from "../page/registrationEn/CardStudentEn";
import RegistrationStudentInfoEn from "../page/registrationEn/RegistrationStudentInfoEn";
import PresentATemporaryStudentCard from "../page/registration/PresentATemporaryStudentCard";
import QcodePresentATemporaryStudentCard from "../page/registration/QRcodePresentAtemporaryStudentCard";
import TuitionPaymentChannels from "../page/registration/TuitionPaymentChannels";
import PayThroughSLMS from "../page/registration/PayThroughSLMS";
import Onepay from "../page/registration/Onepay";
import PaymentSuccess from "../page/payment/success"
import SuccessPayment from "../page/payment/SuccessPayment"
import PaymentFail from "../page/payment/error"


const Main = styled.main`
  /* position: relative; */
  overflow: hidden;
  transition: all 0.15s;
  padding: 0px;
  margin-left: ${(props) => (props.expanded ? 0 : 0)}px;
`;
function Routes() {

	return (
		<Router>
			<Switch>
				{/* Before login routes */}
				<PublicRoute exact path="/" component={Login} />
				{/* <PublicRoute exact path="/login-from/:studentType" component={FormLogin} /> */}
				<PublicRoute exact path="/login-from" component={FormLogin} />
				{/* <PublicRoute exact path="/login-from-en/:studentType" component={FormLoginEn} /> */}
				<PublicRoute exact path="/login-from-en" component={FormLoginEn} />

				<Route
					render={({ location, history }) => (
						<React.Fragment>
							{/* sidenav */}
							{/* <CustomSideNav location={location} history={history} /> */}

							<Main>
								{/* navbar */}
								<CustomNavbar />

								{/* Contents */}
								<div
									style={{
										backgroundColor: "#F5F5F5",
										marginTop: 80,
									}}
								>
									{/* private routes */}
									<PrivateRoute
										// path="/registrationStudent/:studenttype"
										path="/registrationStudent"
										exact
										component={(props) => <RegistrationStudent />}
									/>
									<PrivateRoute
										// path="/registrationStudentEn/:studenttype"
										path="/registrationStudentEn"
										exact
										component={(props) => <RegistrationStudentEn />}
									/>
									<PrivateRoute
										path="/cardstudent/:student"
										exact
										component={(props) => <CardStudent />}
									/>
									<PrivateRoute
										path="/cardstudentEn/:student"
										// path="/cardstudentEn"
										exact
										component={(props) => <CardStudentEn />}
									/>
									<PrivateRoute
										path="/registrationstudentinfo"
										exact
										component={(props) => <RegistrationStudentInfo />}
									/>
									<PrivateRoute
										path="/registrationstudentinfoEn"
										exact
										component={(props) => <RegistrationStudentInfoEn />}
									/>
									<PrivateRoute
										path="/presentatemporarystudentcard"
										exact
										component={(props) => <PresentATemporaryStudentCard />}
									/>
									<PrivateRoute
										path="/qrcodepresentatemporarystudentcard"
										exact
										component={(props) => <QcodePresentATemporaryStudentCard />}
									/>
									<PrivateRoute
										path="/tuitionpaymentchannels"
										exact
										component={(props) => <TuitionPaymentChannels />}
									/>
									<PrivateRoute
										path="/onepay"
										exact
										component={(props) => <Onepay />}
									/>
									<PrivateRoute
										path="/paythroughslms"
										exact
										component={(props) => <PayThroughSLMS />}
									/>
									<PrivateRoute
										path="/payment/success"
										exact
										component={(props) => <PaymentSuccess />}
									/>
									<PrivateRoute
										path="/payment/successpayment"
										exact
										component={(props) => <SuccessPayment />}
									/>
									<PrivateRoute
										path="/payment/fail"
										exact
										component={(props) => <PaymentFail />}
									/>
								</div>
							</Main>
						</React.Fragment>
					)}
				/>
			</Switch>
		</Router>
	);
}

export default Routes;
