import * as _ from 'lodash'

import { USER_KEY } from '../consts'

export const getUserDataFromLCStorage = () => {
    const user = JSON.parse(localStorage.getItem(USER_KEY))
    return user
}

export const useAuth = () => {
    const userData = getUserDataFromLCStorage()

    //  check if user login or not
    const isAuthenticated = !_.isEmpty(userData)
    return isAuthenticated
}
