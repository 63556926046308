export const LAO_ADDRESS = [
  {
    code: "01",
    province_name: "ນະຄອນຫຼວງວຽງຈັນ",
    district_list: [
      
      {
        code: "0101",
        district: "ຈັນທະບູລີ",
        village: [
          {
            code: "0101001",
            villageName: "ບ້ານໜອງປີງ",
            coordinates: [102.59327173233032, 18.00175398229809]
          },
          {
            code: "0101002",
            villageName: "ບ້ານດົງປາແຫຼບ",
            coordinates: [102.60857105255127, 17.989999011158464]
          },
          {
            code: "0101003",
            villageName: "ບ້ານຫ້ວຍຫົງ",
            coordinates: [102.61831283569336, 18.018609569706076]
          },
          {
            code: "0101004",
            villageName: "ບ້ານໂພນສະຫວ່າງ",
            coordinates: [102.62449264526367, 18.00230497734639]
          },
          {
            code: "0101005",
            villageName: "ບ້ານໜອງທາເໜືອ",
            coordinates: [102.61003017425537, 18.007467921353]
          },
          {
            code: "0101006",
            villageName: "ບ້ານໜອງທາໃຕ້",
            coordinates: [102.61625289916992, 18.0006111722644]
          },
          {
            code: "0101007",
            villageName: "ບ້ານດອນແດງ",
            coordinates: [102.60844230651855, 17.997182697725243]
          },
          {
            code: "0101008",
            villageName: "ບ້ານໂພນຕ້ອງສະຫວາດ",
            coordinates: [102.62063026428223, 17.991713326572974]
          },
          {
            code: "0101009",
            villageName: "ບ້ານດົງປາແຫຼບ",
            coordinates: [102.60766983032225, 17.99065208566143]
          },
          {
            code: "0101010",
            villageName: "ບ້ານໂພນຕ້ອງຈອມມະນີ",
            coordinates: [102.62517929077148, 17.996039858067522]
          },
          {
            code: "0101011",
            villageName: "ບ້ານຮ່ອງແຊງ",
            coordinates: [102.62406349182129, 17.985427421958896]
          },
          {
            code: "0101012",
            villageName: "ບ້ານຮ່ອງໄກ່ແກ້ວ",
            coordinates: [102.6229476928711, 17.983141582950182]
          },
          {
            code: "0101013",
            villageName: "ບ້ານທົ່ງສາງນາງ",
            coordinates: [102.61515855789185, 17.980365881501005]
          },
          {
            code: "0101014",
            villageName: "ບ້ານສະຫວ່າງ",
            coordinates: [102.61097431182861, 17.981957833257965]
          },
          {
            code: "0101015",
            villageName: "ບ້ານຮ່ອງຄ້າ",
            coordinates: [102.60357141494751, 17.97782485183866]
          },
          {
            code: "0101016",
            villageName: "ບ້ານທົ່ງຕູມ",
            coordinates: [102.60539531707764, 17.97293658351148]
          },
          {
            code: "0101017",
            villageName: "ບ້ານດົງໝ້ຽງ",
            coordinates: [102.60912895202637, 17.976569630921247]
          },
          {
            code: "0101018",
            villageName: "ບ້ານສີດຳດວນ",
            coordinates: [102.61728286743164, 17.97518173399143]
          },
          {
            code: "0101019",
            villageName: "ບ້ານສີບຸນເຮືອງ",
            coordinates: [102.6213812828064, 17.977059474292087]
          },
          {
            code: "0101020",
            villageName: "ບ້ານຂົວຫຼວງ",
            coordinates: [102.60312080383301, 17.970609761080507]
          },
          {
            code: "0101021",
            villageName: "ບ້ານສີສະຫວາດ",
            coordinates: [102.6128625869751, 17.974059162319133]
          },
          {
            code: "0101022",
            villageName: "ບ້ານທົ່ງຂັນຄຳ",
            coordinates: [102.60955810546875, 17.9738550576121]
          },
          {
            code: "0101023",
            villageName: "ບ້ານສີຫອມ",
            coordinates: [102.5993013381958, 17.96871154113327]
          },
          {
            code: "0101024",
            villageName: "ບ້ານອານຸ",
            coordinates: [102.60410785675049, 17.96871154113327]
          },
          {
            code: "0101025",
            villageName: "ບ້ານສາຍລົມ",
            coordinates: [102.61247634887695, 17.969997434293866]
          },
          {
            code: "0101026",
            villageName: "ບ້ານຫັດສະດີ",
            coordinates: [102.6133131980896, 17.96681330079009]
          },
          {
            code: "0101027",
            villageName: "ບ້ານຫາຍໂສກ",
            coordinates: [102.6029920578003, 17.96611931535861]
          },
          {
            code: "0101028",
            villageName: "ບ້ານວັດຈັນ",
            coordinates: [102.60170459747314, 17.965119155089116]
          },
          {
            code: "0101029",
            villageName: "ບ້ານມີໄຊ",
            coordinates: [102.60567426681517, 17.96556820733897]
          },
          {
            code: "0101030",
            villageName: "ບ້ານຊຽງຍືນ",
            coordinates: [102.60745525360107, 17.963996519471944]
          },
          {
            code: "0101031",
            villageName: "ບ້ານບໍ່ນາງົວ",
            coordinates: [102.60230541229248, 18.014854953729785]
          }
        ]
      },
      {
        code: "0102",
        district: "ສີໂຄດຕະບອງ",
        village: [
          {
            code: "0102001",
            villageName: "ບ້ານນາຄຳ",
            coordinates: [102.58947372436523, 17.975059271973944]
          },
          {
            code: "0102002",
            villageName: "ບ້ານອູບມຸງ",
            coordinates: [102.58533239364624, 17.96803797431374]
          },
          {
            code: "0102003",
            villageName: "ບ້ານໜອງປາໃນ",
            coordinates: [102.58243560791016, 17.969772913940293]
          },
          {
            code: "0102004",
            villageName: "ບ້ານວັດໄຕນ້ອຍທ່າ",
            coordinates: [102.5786805152893, 17.975630760662373]
          },
          {
            code: "0102005",
            villageName: "ບ້ານວັດໄຕນ້ອຍທົ່ງ",
            coordinates: [102.57994651794434, 17.969752502984914]
          },
          {
            code: "0102006",
            villageName: "ບ້ານໜອງສະໂນຄຳ",
            coordinates: [102.57876634597778, 17.975508298956264]
          },
          {
            code: "0102007",
            villageName: "ບ້ານວັດໄຕໃຫຍ່ທ່າ",
            coordinates: [102.57728576660156, 17.9698341467923]
          },
          {
            code: "0102008",
            villageName: "ບ້ານອາກາດ",
            coordinates: [102.57181406021117, 17.969507571336358]
          },
          {
            code: "0102009",
            villageName: "ບ້ານເມືອງວາທົ່ງ",
            coordinates: [102.56545186042786, 17.97279370933321]
          },
          {
            code: "0102010",
            villageName: "ບ້ານສີໄຄທ່າ",
            coordinates: [102.55964756011961, 17.973385615890752]
          },
          {
            code: "0102011",
            villageName: "ບ້ານສີໄຄທົ່ງໃຕ້",
            coordinates: [102.56076335906982, 17.975263375289256]
          },
          {
            code: "0102012",
            villageName: "ບ້ານສີໄຄທົ່ງເໜືອ",
            coordinates: [102.55754470825195, 17.9757940428051]
          },
          {
            code: "0102013",
            villageName: "ບ້ານຍາພະ",
            coordinates: [102.55720138549805, 17.97277329872688]
          },
          {
            code: "0102014",
            villageName: "ບ້ານສີບຸນເຮືອງທ່າ",
            coordinates: [102.54833936691284, 17.974079572776866]
          },
          {
            code: "0102015",
            villageName: "ບ້ານສີບຸນເຮືອງທົ່ງ",
            coordinates: [102.55226612091064, 17.974814347684184]
          },
          {
            code: "0102016",
            villageName: "ບ້ານສີຊົມຊື່ນ",
            coordinates: [102.54509925842285, 17.976590041088826]
          },
          {
            code: "0102017",
            villageName: "ບ້ານໂນນສະຫວ່າງ",
            coordinates: [102.6579236984253, 17.981427184267414]
          },
          {
            code: "0102018",
            villageName: "ບ້ານເກົ້າລ້ຽວ",
            coordinates: [102.52922058105469, 17.978018746957098]
          },
          {
            code: "0102019",
            villageName: "ບ້ານດ່ານຄຳ",
            coordinates: [102.52267599105835, 17.978467766413893]
          },
          {
            code: "0102020",
            villageName: "ບ້ານໂນນຂີ້ເຫຼັກ",
            coordinates: [102.51771926879883, 17.985019238592795]
          },
          {
            code: "0102021",
            villageName: "ບ້ານໂນນແກ້ວ",
            coordinates: [102.51400709152222, 17.98808059082685]
          },
          {
            code: "0102022",
            villageName: "ບ້ານໂພນສະຫວາດເໜືອ",
            coordinates: [102.50853538513184, 17.98814181732985]
          },
          {
            code: "0102023",
            villageName: "ບ້ານໂພນສົມບູນ",
            coordinates: [102.50797748565674, 17.992366394730276]
          },
          {
            code: "0102024",
            villageName: "ບ້ານຕາດທອງ",
            coordinates: [102.50001668930054, 17.987835684602413]
          },
          {
            code: "0102025",
            villageName: "ບ້ານໜອງດາ",
            coordinates: [102.51023054122925, 17.977528906247375]
          },
          {
            code: "0102026",
            villageName: "ບ້ານໃໝ່",
            coordinates: [102.46969699859619, 17.979141293458028]
          },
          {
            code: "0102027",
            villageName: "ບ້ານຫ້ວຍຫ້ອມ",
            coordinates: [102.44845390319824, 17.983468133192407]
          },
          {
            code: "0102028",
            villageName: "ບ້ານອ່າງ",
            coordinates: [102.42931365966797, 17.99016228001082]
          },
          {
            code: "0102029",
            villageName: "ບ້ານໂພສີ",
            coordinates: [102.55207300186156, 17.984151845805467]
          },
          {
            code: "0102030",
            villageName: "ບ້ານນາແຮ່",
            coordinates: [102.55265235900879, 17.98636624011826]
          },
          {
            code: "0102031",
            villageName: "ບ້ານວຽງສະຫວັນ",
            coordinates: [102.54878997802733, 17.981917014161482]
          },
          {
            code: "0102032",
            villageName: "ບ້ານທົ່ງປົ່ງ",
            coordinates: [102.54288375377655, 17.99449395156912]
          },
          {
            code: "0102033",
            villageName: "ບ້ານນາເລົ່າ",
            coordinates: [102.54292130470276, 17.99894796228557]
          },
          {
            code: "0102034",
            villageName: "ບ້ານໜອງໜ້ຽວ",
            coordinates: [102.54385471343993, 18.00436609170315]
          },
          {
            code: "0102035",
            villageName: "ບ້ານໜອງແຕ່ງໃຕ້",
            coordinates: [102.54638671875, 18.023955678254367]
          },
          {
            code: "0102036",
            villageName: "ບ້ານໜອງແຕ່ງເໜືອ",
            coordinates: [102.54638671875, 18.023955678254367]
          },
          {
            code: "0102037",
            villageName: "ບ້ານວຽງຄຳ",
            coordinates: [102.53059387207031, 18.014977388121583]
          },
          {
            code: "0102038",
            villageName: "ບ້ານຈຳປາ",
            coordinates: [102.5105094909668, 18.01399791060483]
          },
          {
            code: "0102039",
            villageName: "ບ້ານດົງນາໂຊກໃຕ້",
            coordinates: [102.58715629577637, 17.985590695040997]
          },
          {
            code: "0102040",
            villageName: "ບ້ານດົງນາໂຊກເໜືອ",
            coordinates: [102.58230686187744, 17.98412123186451]
          },
          {
            code: "0102041",
            villageName: "ບ້ານປາກທ້າງ",
            coordinates: [102.56913185119629, 17.995550067375916]
          },
          {
            code: "0102042",
            villageName: "ບ້ານດົງນາທອງ",
            coordinates: [102.5625228881836, 17.999305094585168]
          },
          {
            code: "0102043",
            villageName: "ບ້ານຫຼັກຫີນ",
            coordinates: [102.56831645965576, 18.01914010681498]
          },
          {
            code: "0102044",
            villageName: "ບ້ານໜອງບຶກໃຕ້",
            coordinates: [102.5851821899414, 18.02542480942699]
          },
          {
            code: "0102045",
            villageName: "ບ້ານໜອງບຶກເໜືອ",
            coordinates: [102.5851821899414, 18.02542480942699]
          },
          {
            code: "0102046",
            villageName: "ບ້ານດົງກະເລົາ",
            coordinates: [102.57265090942383, 18.026730693511876]
          },
          {
            code: "0102047",
            villageName: "ບ້ານຊຳເກດ",
            coordinates: [102.57685661315918, 18.020935759018275]
          },
          {
            code: "0102048",
            villageName: "ບ້ານຂຸນຕາທົ່ງ",
            coordinates: [102.59191989898682, 17.96944633837116]
          },
          {
            code: "0102049",
            villageName: "ບ້ານຂຸນຕາທ່າ",
            coordinates: [102.59191989898682, 17.96944633837116]
          },
          {
            code: "0102050",
            villageName: "ບ້ານສີຖານເໜືອ",
            coordinates: [102.5955033302307, 17.967629750749072]
          },
          {
            code: "0102051",
            villageName: "ບ້ານໜອງດ້ວງເໜືອ",
            coordinates: [102.59500980377197, 17.973875468093407]
          },
          {
            code: "0102052",
            villageName: "ບ້ານໜອງດ້ວງໃຕ້",
            coordinates: [102.59500980377197, 17.973875468093407]
          },
          {
            code: "0102053",
            villageName: "ບ້ານໜອງດ້ວງທົ່ງ",
            coordinates: [102.59500980377197, 17.973875468093407]
          },
          {
            code: "0102054",
            villageName: "ບ້ານໂພນສະຫວາດໃຕ້",
            coordinates: [102.60264873504639, 17.9796311296945]
          },
          {
            code: "0102055",
            villageName: "ບ້ານໜອງບົວທອງໃຕ້",
            coordinates: [102.60174751281737, 17.989152051562243]
          },
          {
            code: "0102056",
            villageName: "ບ້ານໜອງບົວທອງເໜືອ",
            coordinates: [102.60174751281737, 17.989152051562243]
          },
          {
            code: "0102057",
            villageName: "ບ້ານໂພນຄຳ",
            coordinates: [102.5947093963623, 17.98297830760252]
          },
          {
            code: "0102058",
            villageName: "ບ້ານຈັນສະຫວ່າງ",
            coordinates: [102.52080917358398, 18.020935759018275]
          }
        ]
      },
      {
        code: "0104",
        district: "ສີສັດຕະນາກ",
        village: [
          {
            code: "0104001",
            villageName: "ບ້ານເພັຍວັດ",
            coordinates: [102.61520147323607, 17.957821896105415]
          },
          {
            code: "0104002",
            villageName: "ບ້ານເກົ້າຍອດ",
            coordinates: [102.61589884757996, 17.959852911730035]
          },
          {
            code: "0104003",
            villageName: "ບ້ານສີເມືອງ",
            coordinates: [102.61806607246399, 17.95796478238983]
          },
          {
            code: "0104004",
            villageName: "ບ້ານໜອງຈັນ",
            coordinates: [102.61711120605469, 17.96346581651575]
          },
          {
            code: "0104005",
            villageName: "ບ້ານດົງປ່າລານທ່າ",
            coordinates: [102.62303352355957, 17.962628935539]
          },
          {
            code: "0104006",
            villageName: "ບ້ານດົງປ່າລານທົ່ງ",
            coordinates: [102.62095212936401, 17.960771453846373]
          },
          {
            code: "0104007",
            villageName: "ບ້ານໂພນສີນວນ",
            coordinates: [102.6282262802124, 17.960791865839184]
          },
          {
            code: "0104008",
            villageName: "ບ້ານສະພານທອງເໜືອ",
            coordinates: [102.62970685958861, 17.95564796910499]
          },
          {
            code: "0104009",
            villageName: "ບ້ານສະພານທອງກາງ",
            coordinates: [102.63462066650389, 17.95821993618189]
          },
          {
            code: "0104010",
            villageName: "ບ້ານທົ່ງພານທອງ",
            coordinates: [102.63322055339813, 17.95038654689035]
          },
          {
            code: "0104011",
            villageName: "ບ້ານທາດຂາວ",
            coordinates: [102.61765837669373, 17.954724297194037]
          },
          {
            code: "0104012",
            villageName: "ບ້ານພະໂພ",
            coordinates: [102.61897802352905, 17.954790638431366]
          },
          {
            code: "0104013",
            villageName: "ບ້ານພະໄຊ",
            coordinates: [102.61980414390564, 17.953320919033732]
          },
          {
            code: "0104014",
            villageName: "ບ້ານໂພນສະຫວັນເໜືອ",
            coordinates: [102.62383818626404, 17.956648182969964]
          },
          {
            code: "0104015",
            villageName: "ບ້ານໂພນສະຫວັນໃຕ້",
            coordinates: [102.62447655200958, 17.954086399411032]
          },
          {
            code: "0104016",
            villageName: "ບ້ານບຶງຂະຫຍອງ",
            coordinates: [102.61978268623352, 17.947834879364198]
          },
          {
            code: "0104017",
            villageName: "ບ້ານໂສກປ່າຫຼວງ",
            coordinates: [102.62531876564026, 17.94508414054285]
          },
          {
            code: "0104018",
            villageName: "ບ້ານສະພານທອງໃຕ້",
            coordinates: [102.62603759765625, 17.950646814909074]
          },
          {
            code: "0104019",
            villageName: "ບ້ານວັດນາກ",
            coordinates: [102.62142419815063, 17.937214443758705]
          },
          {
            code: "0104020",
            villageName: "ບ້ານທ່າພະລານໄຊ",
            coordinates: [102.62389183044434, 17.934335945855917]
          },
          {
            code: "0104021",
            villageName: "ບ້ານພັນໝັ້ນ",
            coordinates: [102.61626362800598, 17.926756698279572]
          },
          {
            code: "0104022",
            villageName: "ບ້ານທົ່ງກາງ",
            coordinates: [102.62652039527893, 17.93353975732114]
          },
          {
            code: "0104023",
            villageName: "ບ້ານໂພນປ່າເປົ້າ",
            coordinates: [102.6373028755188, 17.951994078529918]
          },
          {
            code: "0104024",
            villageName: "ບ້ານດອນກອຍ",
            coordinates: [102.64343976974487, 17.942991713169516]
          },
          {
            code: "0104025",
            villageName: "ບ້ານດອກນົກຂຸ້ມ",
            coordinates: [102.63160586357117, 17.943859308010342]
          },
          {
            code: "0104026",
            villageName: "ບ້ານດອນປ່າໃໝ່",
            coordinates: [102.6237952709198, 17.925679755299555]
          },
          {
            code: "0104027",
            villageName: "ບ້ານວັດສົບ",
            coordinates: [102.61703073978424, 17.92401584000144]
          },
          {
            code: "0104028",
            villageName: "ບ້ານໂພໄຊ",
            coordinates: [102.61787831783295, 17.922076287104098]
          },
          {
            code: "0104029",
            villageName: "ບ້ານສວນມອນ",
            coordinates: [102.62016892433167, 17.918727956343467]
          },
          {
            code: "0104030",
            villageName: "ບ້ານສ້າງເຫວີຍ",
            coordinates: [102.62596786022186, 17.919820254125078]
          },
          {
            code: "0104031",
            villageName: "ບ້ານດົງສະຫວາດ",
            coordinates: [102.6426351070404, 17.91525195457847]
          },
          {
            code: "0104032",
            villageName: "ບ້ານຈອມເພັດເໜືອ",
            coordinates: [102.62275457382202, 17.914756835710776]
          },
          {
            code: "0104033",
            villageName: "ບ້ານຫາຍໂສກ",
            coordinates: [102.61916041374207, 17.918865769986166]
          },
          {
            code: "0104034",
            villageName: "ບ້ານຈອມແຈ້ງ",
            coordinates: [102.61988461017609, 17.91365940236226]
          },
          {
            code: "0104035",
            villageName: "ບ້ານໂຄກນິນ",
            coordinates: [102.6201742887497, 17.91189328605611]
          },
          {
            code: "0104036",
            villageName: "ບ້ານຈອມເພັດໃຕ້",
            coordinates: [102.62118816375732, 17.90952992948338]
          },
          {
            code: "0104037",
            villageName: "ບ້ານໄຊສະຖານ",
            coordinates: [102.6183557510376, 17.9067888047542]
          },
          {
            code: "0104038",
            villageName: "ບ້ານໂພນສະຫວ່າງ",
            coordinates: [102.6154375076294, 17.899432988766513]
          }
        ]
      },
      {
        code: "0108",
        district: "ສັງທອງ",
        village: [
          {
            code: "0108001",
            villageName: "ບ້ານກົ້ວ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108002",
            villageName: "ບ້ານເຕົາໄຫ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108003",
            villageName: "ບ້ານໄຮ່ເໜືອ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108004",
            villageName: "ບ້ານຫອຍປັງ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108005",
            villageName: "ບ້ານນາລາດ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108006",
            villageName: "ບ້ານນາທຽມ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108007",
            villageName: "ບ້ານໜອງບົວ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108008",
            villageName: "ບ້ານນາໝ້ຽງ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108009",
            villageName: "ບ້ານນາຕານ",
            coordinates: [102.827877, 18.045965]
          },
          {
            code: "0108010",
            villageName: "ບ້ານປາກແຖບ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108011",
            villageName: "ບ້ານນາສາວນາງ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108012",
            villageName: "ບ້ານໄຮ່ໃຕ້",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108013",
            villageName: "ບ້ານຫີນສິ່ວ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108014",
            villageName: "ບ້ານເພຍລາດ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108015",
            villageName: "ບ້ານທ່ານາຂາມ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108016",
            villageName: "ບ້ານນາຫອຍ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108017",
            villageName: "ບ້ານຫີນລັບ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108018",
            villageName: "ບ້ານວັງມ້າ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108019",
            villageName: "ບ້ານກ້ວຍ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108020",
            villageName: "ບ້ານນາປໍ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108021",
            villageName: "ບ້ານຫ້ວຍຫຼ້າ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108022",
            villageName: "ບ້ານຫ້ວຍຕົມ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108023",
            villageName: "ບ້ານໃໝ່",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108024",
            villageName: "ບ້ານຊໍ້",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108025",
            villageName: "ບ້ານທ່ານາສາ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108026",
            villageName: "ບ້ານສະນອດ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108027",
            villageName: "ບ້ານໂຄກແຫ້",
            coordinates: [102.356406, 10.045965]
          },
          {
            code: "0108028",
            villageName: "ບ້ານອ່າງນ້ອຍ",
            coordinates: [102.356406, 10.045965]
          },
          {
            code: "0108029",
            villageName: "ບ້ານສະໄກ",
            coordinates: [102.3456046, 18.045965]
          },
          {
            code: "0108030",
            villageName: "ບ້ານຄົກເພີງ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108031",
            villageName: "ບ້ານປາກຕອນ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108032",
            villageName: "ບ້ານນາສ້າງຫີນ",
            coordinates: [102.35646, 18.045965]
          },
          {
            code: "0108033",
            villageName: "ບ້ານດອນກາງຂອງ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108034",
            villageName: "ບ້ານແກ້ງໝໍ້",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108035",
            villageName: "ບ້ານສຳພັນນາ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108036",
            villageName: "ບ້ານຫ້ວຍຫາງ",
            coordinates: [102.356406, 18.045965]
          },
          {
            code: "0108037",
            villageName: "ບ້ານຫ້ວຍບໍ່ຄຳ",
            coordinates: [102.356406, 18.045965]
          }
        ]
      },
      {
        code: "0103",
        district: "ໄຊເສດຖາ",
        village: [
          {
            code: "0103001",
            villageName: "ບ້ານຈອມມະນີເໜືອ",
            coordinates: [102.62947082519531, 18.00624352143254]
          },
          {
            code: "0103002",
            villageName: "ບ້ານຈອມມະນີກາງ",
            coordinates: [102.63011455535889, 17.999672429910195]
          },
          {
            code: "0103003",
            villageName: "ບ້ານຈອມມະນີໃຕ້",
            coordinates: [102.63749599456787, 18.002243755759384]
          },
          {
            code: "0103004",
            villageName: "ບ້ານໂພນພະເນົາ",
            coordinates: [102.63668060302734, 17.98918266463051]
          },
          {
            code: "0103005",
            villageName: "ບ້ານໂພນເຄັງ",
            coordinates: [102.63333320617676, 17.983304858146806]
          },
          {
            code: "0103006",
            villageName: "ບ້ານໜອງສ້າງທໍ່",
            coordinates: [102.62841939926147, 17.983493644904645]
          },
          {
            code: "0103007",
            villageName: "ບ້ານໂພນສະອາດ",
            coordinates: [102.62826919555664, 17.977181934922484]
          },
          {
            code: "0103008",
            villageName: "ບ້ານທາດຫຼວງເໜືອ",
            coordinates: [102.6356828212738, 17.98122308807708]
          },
          {
            code: "0103009",
            villageName: "ບ້ານໜອງບອນ",
            coordinates: [102.62449264526367, 17.968078796618325]
          },
          {
            code: "0103010",
            villageName: "ບ້ານໂພນໄຊ",
            coordinates: [102.6227331161499, 17.970405652387463]
          },
          {
            code: "0103011",
            villageName: "ບ້ານນາໄຊ",
            coordinates: [102.63140201568604, 17.971793586849326]
          },
          {
            code: "0103012",
            villageName: "ບ້ານຝາຍ",
            coordinates: [102.61861324310303, 17.966609187710844]
          },
          {
            code: "0103013",
            villageName: "ບ້ານວຽງຈະເລີນ",
            coordinates: [102.64225959777832, 17.977998336954652]
          },
          {
            code: "0103014",
            villageName: "ບ້ານທາດຫຼວງກາງ",
            coordinates: [102.63736724853516, 17.977386035784402]
          },
          {
            code: "0103015",
            villageName: "ບ້ານທາດຫຼວງໃຕ້",
            coordinates: [102.6416265964508, 17.973977520464622]
          },
          {
            code: "0103016",
            villageName: "ບ້ານຮ່ອງແກ",
            coordinates: [102.63925552368164, 17.972038515328183]
          },
          {
            code: "0103017",
            villageName: "ບ້ານສີສັງວອນ",
            coordinates: [102.63473868370056, 17.969670859139782]
          },
          {
            code: "0103018",
            villageName: "ບ້ານສະພັງໝໍ້",
            coordinates: [102.63384819030762, 17.961751226842342]
          },
          {
            code: "0103019",
            villageName: "ບ້ານໂພນທັນເໜືອ",
            coordinates: [102.63968467712402, 17.959138486783925]
          },
          {
            code: "0103020",
            villageName: "ບ້ານໂພນທັນໃຕ້",
            coordinates: [102.63972759246826, 17.956280758126073]
          },
          {
            code: "0103021",
            villageName: "ບ້ານຮ່ອງສຸພາບ",
            coordinates: [102.66148567199707, 18.009855476734053]
          },
          {
            code: "0103022",
            villageName: "ບ້ານໂນນສະຫວັນ",
            coordinates: [102.66463994979858, 18.004631381899713]
          },
          {
            code: "0103023",
            villageName: "ບ້ານໜອງໜ່ຽງ",
            coordinates: [102.6531171798706, 18.001509095057276]
          },
          {
            code: "0103024",
            villageName: "ບ້ານໂນນສະຫງ່າ",
            coordinates: [102.65843868255615, 17.993019460470038]
          },
          {
            code: "0103025",
            villageName: "ບ້ານອາມອນ",
            coordinates: [102.67154932022095, 18.003223298600417]
          },
          {
            code: "0103026",
            villageName: "ບ້ານແສງສະຫວ່າງ",
            coordinates: [102.66517639160156, 17.994978643183412]
          },
          {
            code: "0103027",
            villageName: "ບ້ານໂຊກຄຳ",
            coordinates: [102.66384601593018, 17.982937488742007]
          },
          {
            code: "0103028",
            villageName: "ບ້ານວັງຊາຍ",
            coordinates: [102.65800952911377, 17.98734587113389]
          },
          {
            code: "0103029",
            villageName: "ບ້ານໂນນສະຫວ່າງ",
            coordinates: [102.6561427116394, 17.981835375940182]
          },
          {
            code: "0103030",
            villageName: "ບ້ານຫົວຂົວ",
            coordinates: [102.65270948410033, 17.973834647128413]
          },
          {
            code: "0103031",
            villageName: "ບ້ານໂນນຄໍ້ເໜືອ",
            coordinates: [102.65800952911377, 17.968446196935165]
          },
          {
            code: "0103032",
            villageName: "ບ້ານຄຳສະຫວາດ",
            coordinates: [102.6734161376953, 17.96501709760045]
          },
          {
            code: "0103033",
            villageName: "ບ້ານໂນນຫວາຍ",
            coordinates: [102.66695737838745, 17.953198441865837]
          },
          {
            code: "0103034",
            villageName: "ບ້ານເມືອງນ້ອຍ",
            coordinates: [102.66754746437073, 17.941797487430613]
          },
          {
            code: "0103035",
            villageName: "ບ້ານຊຽງດາ",
            coordinates: [102.66825556755066, 17.92950772194294]
          },
          {
            code: "0103036",
            villageName: "ບ້ານຂາມງອຍ",
            coordinates: [102.6833724975586, 17.981835375940182]
          },
          {
            code: "0103037",
            villageName: "ບ້ານນາສ້າງໄພ",
            coordinates: [102.70015239715576, 17.95909766241408]
          },
          {
            code: "0103038",
            villageName: "ບ້ານສົມສະຫງ່າ",
            coordinates: [102.6866340637207, 17.96142463644717]
          },
          {
            code: "0103039",
            villageName: "ບ້ານໄຮຄຳ",
            coordinates: [102.70407915115356, 17.96601725844763]
          },
          {
            code: "0103040",
            villageName: "ບ້ານນາຄວາຍກາງ",
            coordinates: [102.70315647125244, 17.95178994833803]
          },
          {
            code: "0103041",
            villageName: "ບ້ານນາຄວາຍໃຕ້",
            coordinates: [102.70315647125244, 17.95178994833803]
          },
          {
            code: "0103042",
            villageName: "ບ້ານໂຊກໃຫຍ່",
            coordinates: [102.6866340637207, 18.022323295908933]
          },
          {
            code: "0103043",
            villageName: "ບ້ານໂຊກນ້ອຍ",
            coordinates: [102.69264221191406, 18.011712441890797]
          },
          {
            code: "0103044",
            villageName: "ບ້ານຊຳເຄ້",
            coordinates: [102.67667770385742, 18.004202835999155]
          },
          {
            code: "0103045",
            villageName: "ບ້ານໂພນທອງ",
            coordinates: [102.69315719604491, 17.99359089100915]
          },
          {
            code: "0103046",
            villageName: "ບ້ານນາບຽນ",
            coordinates: [102.70071029663085, 17.99046840870179]
          },
          {
            code: "0103047",
            villageName: "ບ້ານດຸ່ງໃຫຍ່",
            coordinates: [102.70264148712158, 17.98385591082042]
          },
          {
            code: "0103048",
            villageName: "ບ້ານດຸ່ງກາງ",
            coordinates: [102.71122455596922, 17.977896286907065]
          },
          {
            code: "0103049",
            villageName: "ບ້ານນາໄຫ",
            coordinates: [102.73641586303711, 17.97612060663762]
          },
          {
            code: "0103050",
            villageName: "ບ້ານນາໂນ",
            coordinates: [102.75890350341795, 17.988733672430268]
          },
          {
            code: "0103051",
            villageName: "ບ້ານຈອມສີ",
            coordinates: [102.74642586708069, 17.968915651783703]
          },
          {
            code: "0103052",
            villageName: "ບ້ານໂນນຄໍ້ໃຕ້",
            coordinates: [102.66413569450378, 17.969466749361917]
          }
        ]
      },
      {
        code: "0106",
        district: "ໄຊທານີ",
        village: [
          {
            code: "0106001",
            villageName: "ບ້ານຂຸດສາມບາດ",
            coordinates: [102.62354850769043, 18.12953860004488]
          },
          {
            code: "0106002",
            villageName: "ບ້ານທົ່ງມັ່ງ",
            coordinates: [102.692217, 18.233069]
          },
          {
            code: "0106003",
            villageName: "ບ້ານນາກຸງ",
            coordinates: [102.669471, 18.74385]
          },
          {
            code: "0106004",
            villageName: "ບ້ານໂພນຄໍ້",
            coordinates: [102.628054, 17.995885]
          },
          {
            code: "0106005",
            villageName: "ບ້ານຫາດກ້ຽງ",
            coordinates: [108.669471, 18.074385]
          },
          {
            code: "0106006",
            villageName: "ບ້ານໂພນໂຮງ",
            coordinates: [102.669471, 18.074385]
          },
          {
            code: "0106007",
            villageName: "ບ້ານໂພນໄຮຄຳ",
            coordinates: [102.669471, 18.074385]
          },
          {
            code: "0106008",
            villageName: "ບ້ານໄຮ່",
            coordinates: [103.125982, 19, 272878]
          },
          {
            code: "0106009",
            villageName: "ບ້ານທ່າສະຫວ່າງ",
            coordinates: [102.669471, 18.074385]
          },
          {
            code: "0106010",
            villageName: "ບ້ານເວີນຄຳ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106011",
            villageName: "ບ້ານຈະເລີນໄຊ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106012",
            villageName: "ບ້ານໜອງເຂັງ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106013",
            villageName: "ບ້ານໜອງໂນ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106014",
            villageName: "ບ້ານໂພນງາມ",
            coordinates: [102.669643, 18.074712]
          },
          {
            code: "0106015",
            villageName: "ບ້ານໂພນໄຊ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106016",
            villageName: "ບ້ານສາມສະອາດ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106017",
            villageName: "ບ້ານທ່າງ່ອນ",
            coordinates: [102.62354850769043, 18.12953860004488]
          },
          {
            code: "0106018",
            villageName: "ບ້ານນາ",
            coordinates: [102.63071537017822, 18.131802143510768]
          },
          {
            code: "0106019",
            villageName: "ບ້ານພູຄຳ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106020",
            villageName: "ບ້ານແກ້ງໄຄ້",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106021",
            villageName: "ບ້ານທ່າສົມມໍ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106022",
            villageName: "ບ້ານອຸດົມຜົນ",
            coordinates: [102.66607761383057, 18.129232713546955]
          },
          {
            code: "0106023",
            villageName: "ບ້ານລາດຄວາຍ",
            coordinates: [102.65750527381897, 18.112765034787262]
          },
          {
            code: "0106024",
            villageName: "ບ້ານໂນນສະອາດ",
            coordinates: [102.65011310577393, 18.110796967104847]
          },
          {
            code: "0106025",
            villageName: "ບ້ານດົງໝາກຄວາຍ",
            coordinates: [102.69418716430664, 18.09299157827101]
          },
          {
            code: "0106026",
            villageName: "ບ້ານດ່ານຊ້າງ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106027",
            villageName: "ບ້ານດອນຕິ້ວ",
            coordinates: [102.66982197761536, 18.068554665691053]
          },
          {
            code: "0106028",
            villageName: "ບ້ານໄຊສະຫວ່າງ",
            coordinates: [102.67251491546631, 18.057579386170282]
          },
          {
            code: "0106029",
            villageName: "ບ້ານດອນໜູນ",
            coordinates: [102.66099214553833, 18.050826596620286]
          },
          {
            code: "0106030",
            villageName: "ບ້ານໄຊ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106031",
            villageName: "ບ້ານນາແຄ",
            coordinates: [102.66938209533691, 18.03976859867531]
          },
          {
            code: "0106032",
            villageName: "ບ້ານສະພັງເມິກ",
            coordinates: [102.65305280685423, 18.034545391135957]
          },
          {
            code: "0106033",
            villageName: "ບ້ານຊ້າງຄູ້",
            coordinates: [102.64564990997314, 18.03056667199232]
          },
          {
            code: "0106034",
            villageName: "ບ້ານພະຂາວ",
            coordinates: [102.65152931213379, 18.02358839354596]
          },
          {
            code: "0106035",
            villageName: "ບ້ານສີວິໄລ",
            coordinates: [102.63305425643921, 18.013630605132235]
          },
          {
            code: "0106036",
            villageName: "ບ້ານຄຳຮຸ່ງ",
            coordinates: [102.6316487789154, 18.029005765303445]
          },
          {
            code: "0106037",
            villageName: "ບ້ານໜອງວຽງຄຳ",
            coordinates: [102.643018, 18.05414]
          },
          {
            code: "0106038",
            villageName: "ບ້ານຫ້ວຍແດນເມືອງ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106039",
            villageName: "ບ້ານຕານມີໄຊ",
            coordinates: [102.62895584106445, 18.035708384357818]
          },
          {
            code: "0106040",
            villageName: "ບ້ານໜອງພະຍາ",
            coordinates: [102.61465430259705, 18.040227662237264]
          },
          {
            code: "0106041",
            villageName: "ບ້ານນາທົ່ມ",
            coordinates: [102.6287841796875, 18.053866404082555]
          },
          {
            code: "0106042",
            villageName: "ບ້ານວຽງແກ້ວ",
            coordinates: [102.62915968894958, 18.064882715413297]
          },
          {
            code: "0106043",
            villageName: "ບ້ານໂນນແສງຈັນ",
            coordinates: [102.62501835823059, 18.07680607382201]
          },
          {
            code: "0106044",
            villageName: "ບ້ານຫ້ວຍເຕີຍ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106045",
            villageName: "ບ້ານດົງສ້າງຫີນ",
            coordinates: [102.6917839050293, 18.060027463316032]
          },
          {
            code: "0106046",
            villageName: "ບ້ານໂພຄຳ",
            coordinates: [102.70860671997069, 18.06777948258044]
          },
          {
            code: "0106047",
            villageName: "ບ້ານໂຄກນ້ອຍ",
            coordinates: [102.71135330200195, 18.05096940754354]
          },
          {
            code: "0106048",
            villageName: "ບ້ານນາໂຄກໃຫຍ່",
            coordinates: [102.7043581008911, 18.04505285786702]
          },
          {
            code: "0106049",
            villageName: "ບ້ານສະພັງຄະນົງ",
            coordinates: [102.71775, 18.040086]
          },
          {
            code: "0106050",
            villageName: "ບ້ານນາລ້ອມ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106051",
            villageName: "ບ້ານໜອງບົວ",
            coordinates: [110115, 16.213881]
          },
          {
            code: "0106052",
            villageName: "ບ້ານນາມົນ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106053",
            villageName: "ບ້ານດົງບັງ",
            coordinates: [102.74173736572266, 18.1215445906579]
          },
          {
            code: "0106054",
            villageName: "ບ້ານໂນນແຕ້",
            coordinates: [102.708338, 18.12298]
          },
          {
            code: "0106055",
            villageName: "ບ້ານໂພນຕ້ອງ",
            coordinates: [102.61977195739746, 17.995468435461756]
          },
          {
            code: "0106056",
            villageName: "ບ້ານຫາດພະອິນ",
            coordinates: [102.69847869873047, 18.15009294705388]
          },
          {
            code: "0106057",
            villageName: "ບ້ານດວງບຸດດີ",
            coordinates: [102.72937774658203, 18.144220523003757]
          },
          {
            code: "0106058",
            villageName: "ບ້ານທ່າດອກຄຳ",
            coordinates: [102.73555755615233, 18.16020610371821]
          },
          {
            code: "0106059",
            villageName: "ບ້ານນາແຖ",
            coordinates: [102.73538589477539, 18.16868765464038]
          },
          {
            code: "0106060",
            villageName: "ບ້ານໂພນງາມສອງ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106061",
            villageName: "ບ້ານໂພນງາມໜຶ່ງ",
            coordinates: [102.75392532348633, 18.164691590622734]
          },
          {
            code: "0106062",
            villageName: "ບ້ານທ່າດິນແດງເໜືອ",
            coordinates: [102.77332305908203, 18.171623480126566]
          },
          {
            code: "0106063",
            villageName: "ບ້ານທ່າດິນແດງໃຕ້",
            coordinates: [102.77332305908203, 18.171623480126566]
          },
          {
            code: "0106064",
            villageName: "ບ້ານນາພອກ",
            coordinates: [102.539384, 18.07707]
          },
          {
            code: "0106065",
            villageName: "ບ້ານປາກຊາບເກົ່າ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106066",
            villageName: "ບ້ານປາກຊາບໃໝ່",
            coordinates: [102.79169082641602, 18.127744058290958]
          },
          {
            code: "0106067",
            villageName: "ບ້ານແສນອຸດົມ",
            coordinates: [102.6581818, 18.04663]
          },
          {
            code: "0106068",
            villageName: "ບ້ານສົມສະໄໝ",
            coordinates: [102.82310485839844, 18.137369112317987]
          },
          {
            code: "0106069",
            villageName: "ບ້ານເວີນແທ່ນ",
            coordinates: [102.80902862548828, 18.142915513093]
          },
          {
            code: "0106070",
            villageName: "ບ້ານປ່າໄລ່",
            coordinates: [101.299181, 18.191724]
          },
          {
            code: "0106071",
            villageName: "ບ້ານດຸ່ນອຽນ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106072",
            villageName: "ບ້ານນາຂາວ",
            coordinates: [102.86104202270508, 18.111102885812517]
          },
          {
            code: "0106073",
            villageName: "ບ້ານທ່າຈຳປາ",
            coordinates: [102.82894134521483, 18.16509935644695]
          },
          {
            code: "0106074",
            villageName: "ບ້ານນາຕານ",
            coordinates: [102.82962799072264, 18.206197263056133]
          },
          {
            code: "0106075",
            villageName: "ບ້ານນາໂງມໃໝ",
            coordinates: [103.125982, 19.27278]
          },
          {
            code: "0106076",
            villageName: "ບ້ານນາໂງມເກົ່າ",
            coordinates: [102.8316879272461, 18.238481676022758]
          },
          {
            code: "0106077",
            villageName: "ບ້ານນາຄວາຍ",
            coordinates: [102.7056884765625, 17.945094347445007]
          },
          {
            code: "0106078",
            villageName: "ບ້ານນາຄັນທຸງ",
            coordinates: [103.125982, 19.27278]
          },
          {
            code: "0106079",
            villageName: "ບ້ານຫົວນາ",
            coordinates: [103.125982, 19.27278]
          },
          {
            code: "0106080",
            villageName: "ບ້ານຫົວຂົວ",
            coordinates: [102.65281677246094, 17.974161214584193]
          },
          {
            code: "0106081",
            villageName: "ບ້ານໂພນແພງ",
            coordinates: [103.125982, 27278]
          },
          {
            code: "0106082",
            villageName: "ບ້ານຊຳບອນ",
            coordinates: [103.125982, 19.27278]
          },
          {
            code: "0106083",
            villageName: "ບ້ານໂຄກສີວິໄລ",
            coordinates: [102.74680137634277, 18.074062447155864]
          },
          {
            code: "0106084",
            villageName: "ບ້ານໄຊສົມບູນ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106085",
            villageName: "ບ້ານໂຄກສະອາດ",
            coordinates: [102.7376389503479, 18.05086739975304]
          },
          {
            code: "0106086",
            villageName: "ບ້ານທ່ານາກ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106087",
            villageName: "ບ້ານໂນນທອງ",
            coordinates: [102.75993347167967, 18.062618307812485]
          },
          {
            code: "0106088",
            villageName: "ບ້ານຫົວຊຽງ",
            coordinates: [102.79186248779297, 18.03097474989003]
          },
          {
            code: "0106089",
            villageName: "ບ້ານດົງຄວາຍ",
            coordinates: [102.81211853027344, 18.021180619263927]
          },
          {
            code: "0106090",
            villageName: "ບ້ານສ້າງຫົວບໍ່",
            coordinates: [102.78911590576172, 18.007467921353]
          },
          {
            code: "0106091",
            villageName: "ບ້ານໄຜ່ລ້ອມ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106092",
            villageName: "ບ້ານສ້າງຄອມ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106093",
            villageName: "ບ້ານສົມສະຫວັນ",
            coordinates: [102.78705596923828, 18.065576311901815]
          },
          {
            code: "0106094",
            villageName: "ບ້ານໂຄກສະຫວ່າງ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106095",
            villageName: "ບ້ານໂພນສະຫວັນ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106096",
            villageName: "ບ້ານດອນລຸ່ມ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106097",
            villageName: "ບ້ານນາຜາສຸກ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106098",
            villageName: "ບ້ານບໍ່ເຫຼັກ",
            coordinates: [102.88061141967772, 18.044359180384518]
          },
          {
            code: "0106099",
            villageName: "ບ້ານຫາດວຽງຄຳ",
            coordinates: [102.8404426574707, 18.164936250231516]
          },
          {
            code: "0106100",
            villageName: "ບ້ານນາສາລາ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106101",
            villageName: "ບ້ານສາຍນໍ້າເງິນ",
            coordinates: [102.62972831726074, 18.016609838548597]
          },
          {
            code: "0106102",
            villageName: "ບ້ານໂນນບໍ່ແກ້ວ",
            coordinates: [102.62957811355591, 18.126805995050557]
          },
          {
            code: "0106103",
            villageName: "ບ້ານໜອງສອງຫ້ອງ",
            coordinates: [103.125982, 19.272878]
          },
          {
            code: "0106104",
            villageName: "ບ້ານດົງໂດກ",
            coordinates: [102.63440608978271, 18.042278131521492]
          }
        ]
      },
      {
        code: "0105",
        district: "ນາຊາຍທອງ",
        village: [
          {
            code: "0105001",
            villageName: "ບ້ານນາສ້ຽວ*",
            coordinates: [102.61543750762, 17.8994329887665]
          },
          {
            code: "0105002",
            villageName: "ບ້ານໄຊມຸງຄຸນ",
            coordinates: [102.547233, 18.206293]
          },
          {
            code: "0105003",
            villageName: "ບ້ານສີເກີດ",
            coordinates: [102.547151, 18.034861]
          },
          {
            code: "0105004",
            villageName: "ບ້ານຜາງແຫ້ງ",
            coordinates: [102.547151, 18.034861]
          },
          {
            code: "0105005",
            villageName: "ບ້ານນາລ່ຽນ",
            coordinates: [102.547151, 18.034861]
          },
          {
            code: "0105006",
            villageName: "ບ້ານປາກແຮດ",
            coordinates: [103.664794, 18.398908]
          },
          {
            code: "0105007",
            villageName: "ບ້ານໂພນສະຫວັນ",
            coordinates: [102.66474723815918, 18.005631318282994]
          },
          {
            code: "0105008",
            villageName: "ບ້ານໂພນແກ້ວ",
            coordinates: [106.422871, 15.719767]
          },
          {
            code: "0105009",
            villageName: "ບ້ານດົງຊຽງດີ",
            coordinates: [102.609165, 18.080636]
          },
          {
            code: "0105010",
            villageName: "ບ້ານນາຊາຍທອງໃຕ້",
            coordinates: [102.534622, 18.066121]
          },
          {
            code: "0105011",
            villageName: "ບ້ານນາຊາຍທອງກາງ",
            coordinates: [102.534622, 18.066121]
          },
          {
            code: "0105012",
            villageName: "ບ້ານນາຊາຍທອງເໜືອ",
            coordinates: [102.534622, 18.066121]
          },
          {
            code: "0105013",
            villageName: "ບ້ານນາຄູນເໜືອ",
            coordinates: [102.546892, 18.065086]
          },
          {
            code: "0105014",
            villageName: "ບ້ານນາຄູນໃຕ້",
            coordinates: [102.546892, 18.065086]
          },
          {
            code: "0105015",
            villageName: "ບ້ານໜອງຍາວ",
            coordinates: [102.535593, 18.078909]
          },
          {
            code: "0105016",
            villageName: "ບ້ານດົງບອງ",
            coordinates: [102.535593, 18.088909]
          },
          {
            code: "0105017",
            villageName: "ບ້ານຫົວຊ້າງ",
            coordinates: [102.517464, 18.084717]
          },
          {
            code: "0105018",
            villageName: "ບ້ານດ່ານຊີ",
            coordinates: [102.535593, 18.078909]
          },
          {
            code: "0105019",
            villageName: "ບ້ານຫົວຂົວ",
            coordinates: [102.65234470367432, 17.973997930931784]
          },
          {
            code: "0105020",
            villageName: "ບ້ານນາຊອນ",
            coordinates: [102.992739, 18.082181]
          },
          {
            code: "0105021",
            villageName: "ບ້ານນາຍາງ",
            coordinates: [102.488642, 20.699498]
          },
          {
            code: "0105022",
            villageName: "ບ້ານໜອງຄັນຄູ",
            coordinates: [102.535593, 18.078909]
          },
          {
            code: "0105023",
            villageName: "ບ້ານໍ້າກ້ຽງໃຕ້",
            coordinates: [102.553593, 18.0789009]
          },
          {
            code: "0105024",
            villageName: "ບ້ານນໍ້າກ້ຽງເໜືອ",
            coordinates: [102.535593, 18.078909]
          },
          {
            code: "0105025",
            villageName: "ບ້ານນາຊັບ",
            coordinates: [102.535593, 18.078909]
          },
          {
            code: "0105026",
            villageName: "ບ້ານອີ່ໄລໃຕ້",
            coordinates: [102.502462, 18.146914]
          },
          {
            code: "0105027",
            villageName: "ບ້ານອີ່ໄລເໜືອ",
            coordinates: [102.502462, 18.146914]
          },
          {
            code: "0105028",
            villageName: "ບ້ານສອງຂົວກາງແສນ",
            coordinates: [102.535593, 18.078909]
          },
          {
            code: "0105029",
            villageName: "ບ້ານແຈ້ງສະຫວ່າງ",
            coordinates: [102.535593, 18.078909]
          },
          {
            code: "0105030",

            villageName: "ບ້ານຫ້ວຍນໍ້າເຢັນ",
            coordinates: [102.535593, 18.078909]
          },
          {
            code: "0105031",
            villageName: "ບ້ານສີສະຫວາດ",
            coordinates: [102.615801, 17.97382]
          },
          {
            code: "0105032",
            villageName: "ບ້ານອ່າງນໍ້າຫຸມ",
            coordinates: [102.173946, 18.466182]
          },
          {
            code: "0105033",
            villageName: "ບ້ານນາທ່ອນ",
            coordinates: [102.610328, 17.863382]
          },
          {
            code: "0105034",
            villageName: "ບ້ານສົງເປືອຍໃຕ້",
            coordinates: [102.535593, 18.078909]
          },
          {
            code: "0105035",
            villageName: "ບ້ານສົງເປືອຍເໜືອ",
            coordinates: [102.535593, 18.078909]
          },
          {
            code: "0105036",
            villageName: "ບ້ານນໍ້າຫຸມ",
            coordinates: [102.466182, 18.173946]
          },
          {
            code: "0105037",
            villageName: "ບ້ານເປີກ",
            coordinates: [102.535593, 18.078909]
          },
          {
            code: "0105038",
            villageName: "ບ້ານຫຸມແບ່ງ",
            coordinates: [102.535593, 18.078909]
          },
          {
            code: "0105039",
            villageName: "ບ້ານໂພນຄຳ",
            coordinates: [102.535593, 18.078909]
          },
          {
            code: "0105040",
            villageName: "ບ້ານຫົວນາ",
            coordinates: [102.760456, 18.30402]
          },
          {
            code: "0105041",
            villageName: "ບ້ານນາໜາດ",
            coordinates: [102.535593, 18.078909]
          },
          {
            code: "0105042",
            villageName: "ບ້ານໂພນມ່ວງ",
            coordinates: [102.763168, 18.285795]
          },
          {
            code: "0105043",
            villageName: "ບ້ານຮ່ອງງົວ",
            coordinates: [101.837504, 25.960809]
          },
          {
            code: "0105044",
            villageName: "ບ້ານແສນດິນ",
            coordinates: [102.541361, 18.277617]
          },
          {
            code: "0105045",
            villageName: "ບ້ານຖໍ້າ",
            coordinates: [102.535593, 18.078909]
          },
          {
            code: "0105046",
            villageName: "ບ້ານນາຂ່າ",
            coordinates: [102.541361, 18.277617]
          },
          {
            code: "0105047",
            villageName: "ບ້ານນາງາ",
            coordinates: [102, 535593, 18.078909]
          },
          {
            code: "0105048",
            villageName: "ບ້ານສີວິໄລ",
            coordinates: [102.63106, 18.024339]
          },
          {
            code: "0105049",
            villageName: "ບ້ານໂພນໄຊ",
            coordinates: [101.314924, 18.898096]
          },
          {
            code: "0105050",
            villageName: "ບ້ານໂພສີ",
            coordinates: [102.559536, 18.077452]
          },
          {
            code: "0105051",
            villageName: "ບ້ານໂພນທອງ",
            coordinates: [102.2948583, 18.097185]
          },
          {
            code: "0105052",
            villageName: "ບ້ານບົວ",
            coordinates: [101.289094, 18.392718]
          },
          {
            code: "0105053",
            villageName: "ບ້ານໜອງສະ",
            coordinates: [102.606315, 18.112819]
          },
          {
            code: "0105054",
            villageName: "ບ້ານພັດທະນາ",
            coordinates: [102.606315, 17.991667]
          },
          {
            code: "0105055",
            villageName: "ບ້ານນາດີ",
            coordinates: [103.106909, 19.475577]
          },
          {
            code: "0105056",
            villageName: "ບ້ານດົງຫຼວງ",
            coordinates: [103.106909, 19.4475577]
          }
        ]
      },
      {
        code: "0109",
        district: "ປາກງື່ມ",
        village: [
          {
            code: "0109001",
            villageName: "ບ້ານຊຽງແລນາ",
            coordinates: [102.95082092285156, 18.212393725825347]
          },
          {
            code: "0109002",
            villageName: "ບ້ານເວີນກະເບົາ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109003",
            villageName: "ບ້ານໂນນ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109004",
            villageName: "ບ້ານໂພນຂາມ",
            coordinates: [102.046462, 18.151443]
          },
          {
            code: "0109005",
            villageName: "ບ້ານຫົວນາທົ່ງ",
            coordinates: [102.046462, 18.151443]
          },
          {
            code: "0109006",
            villageName: "ບ້ານນາບົງ",
            coordinates: [102.8788948059082, 18.173906865813116]
          },
          {
            code: "0109007",
            villageName: "ບ້ານທ່າກົກໄຮ",
            coordinates: [102.88009643554688, 18.184100187510154]
          },
          {
            code: "0109008",
            villageName: "ບ້ານໜອງດົງ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109009",
            villageName: "ບ້ານຫ້ວຍນໍ້າຢຸນ",
            coordinates: [103.046462, 10.151443]
          },
          {
            code: "0109010",
            villageName: "ບ້ານທ່າຊ້າງ",
            coordinates: [102.046462, 18.151443]
          },
          {
            code: "0109011",
            villageName: "ບ້ານຊຽງແລທ່າ",
            coordinates: [102.046462, 18.151443]
          },
          {
            code: "0109012",
            villageName: "ບ້ານຝັງແດງ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109013",
            villageName: "ບ້ານປາກກວາງ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109014",
            villageName: "ບ້ານເວີນແຄນ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109015",
            villageName: "ບ້ານສົມປະເສີດ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109016",
            villageName: "ບ້ານໄຮ່",
            coordinates: [103.125982, 19.272828]
          },
          {
            code: "0109017",
            villageName: "ບ້ານໂນນຊາຍ",
            coordinates: [103.046462, 18.515443]
          },
          {
            code: "0109018",
            villageName: "ບ້ານດອນສັງຄີ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109019",
            villageName: "ບ້ານທຸນລົວ",
            coordinates: [103.046462, 18, 151443]
          },
          {
            code: "0109020",
            villageName: "ບ້ານພ້າວ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109021",
            villageName: "ບ້ານດອນແຍງ",
            coordinates: [103.062744140625, 18.098213048099566]
          },
          {
            code: "0109022",
            villageName: "ບ້ານທ່າກອກ",
            coordinates: [103.0733871459961, 18.051214225999225]
          },
          {
            code: "0109023",
            villageName: "ບ້ານນາກຸງ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109024",
            villageName: "ບ້ານໜອງພູວຽງ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109025",
            villageName: "ບ້ານນາຊອນ",
            coordinates: [102.98884391784668, 18.07797898663566]
          },
          {
            code: "0109026",
            villageName: "ບ້ານນາທາມ",
            coordinates: [102.9700469970703, 18.103189616785667]
          },
          {
            code: "0109027",
            villageName: "ບ້ານໜອງແຄນ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109028",
            villageName: "ບ້ານດອນສ້າງໄພ",
            coordinates: [102.91631698608398, 18.11558963214465]
          },
          {
            code: "0109029",
            villageName: "ບ້ານໂນນສົມບູນ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109030",
            villageName: "ບ້ານໜອງບົວທອງ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109031",
            villageName: "ບ້ານໂຄກສາ",
            coordinates: [102.91262626647949, 18.07593913322689]
          },
          {
            code: "0109032",
            villageName: "ບ້ານດອນໄຮ່",
            coordinates: [102.93665885925293, 18.079937223605754]
          },
          {
            code: "0109033",
            villageName: "ບ້ານທາງໂຄ້ງ",
            coordinates: [102.92704582214355, 18.080589964407164]
          },
          {
            code: "0109034",
            villageName: "ບ້ານໂນນສູງ",
            coordinates: [102.97588348388672, 18.05529448339901]
          },
          {
            code: "0109035",
            villageName: "ບ້ານນາເລີດ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109036",
            villageName: "ບ້ານໜອງວັງເຜິ້ງ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109037",
            villageName: "ບ້ານໂດນໃຕ້",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109038",
            villageName: "ບ້ານໂດນເໜືອ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109039",
            villageName: "ບ້ານດົງກະລຶມ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109040",
            villageName: "ບ້ານດົງສານ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109041",
            villageName: "ບ້ານທ່າເດື່ອສິງຫອມ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109042",
            villageName: "ບ້ານຖິ່ນທ່ຽງ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109043",
            villageName: "ບ້ານດອນວັງໂພ",
            coordinates: [103.046462, 18.151443]
          },
          {
            code: "0109044",
            villageName: "ບ້ານໝາກນາວໃຕ້",
            coordinates: [103.046462, 18.151433]
          },
          {
            code: "0109045",
            villageName: "ບ້ານໝາກນາວເໜືອ",
            coordinates: [103.046462, 18.151433]
          },
          {
            code: "0109046",
            villageName: "ບ້ານນາ",
            coordinates: [103.046462, 18.151433]
          },
          {
            code: "0109047",
            villageName: "ບ້ານໝາກໜາວດົງ",
            coordinates: [103.04642, 18.151433]
          },
          {
            code: "0109048",
            villageName: "ບ້ານນາຝາຍ",
            coordinates: [102.91717529296875, 18.047297089799827]
          },
          {
            code: "0109049",
            villageName: "ບ້ານສ້າງໄສ",
            coordinates: [103.046462, 18.151433]
          },
          {
            code: "0109050",
            villageName: "ບ້ານໝາກຮຽວ",
            coordinates: [103.046462, 18.151433]
          },
          {
            code: "0109051",
            villageName: "ບ້ານສົມສະຫວາດ",
            coordinates: [102.74642586708069, 17.968915651783703]
          },
          {
            code: "0109052",
            villageName: "ບ້ານປາກງື່ມ",
            coordinates: [103.046462, 18.151433]
          },
          {
            code: "0109053",
            villageName: "ບ້ານໜອງຂຽດ",
            coordinates: [103.046462, 18.151433]
          }
        ]
      },
      {
        code: "0107",
        district: "ຫາດຊາຍຟອງ",
        village: [
          {
            code: "0107001",
            villageName: "ບ້ານທ່ານາ",
            coordinates: [102.63440608978271, 18.042278131521492]
          },
          {
            code: "0107002",
            villageName: "ບ້ານດອນດູ່",
            coordinates: [102.61861324310303, 17.894950947169846]
          },
          {
            code: "0107003",
            villageName: "ບ້ານບໍ່ໂອ",
            coordinates: [102.61582374572754, 17.871180962684228]
          },
          {
            code: "0107004",
            villageName: "ບ້ານພ້າວ",
            coordinates: [102.630116, 17.904717]
          },
          {
            code: "0107005",
            villageName: "ບ້ານກາງ",
            coordinates: [105.815624, 15.327873]
          },
          {
            code: "0107006",
            villageName: "ບ້ານສົ້ມໂຮງ",
            coordinates: [102.630116, 17.904717]
          },
          {
            code: "0107007",
            villageName: "ບ້ານແກ້ງຍາງ",
            coordinates: [102.60663986206055, 17.87853284412016]
          },
          {
            code: "0107008",
            villageName: "ບ້ານດອນຂາຊ້າຍ",
            coordinates: [102.904717, 17.630116]
          },
          {
            code: "0107009",
            villageName: "ບ້ານນາທາມ",
            coordinates: [102.60464429855347, 17.870016886893367]
          },
          {
            code: "0107010",
            villageName: "ບ້ານແກ້ງປາຢ້າງ",
            coordinates: [102.630116, 17.904717]
          },
          {
            code: "0107011",
            villageName: "ບ້ານຫາດດອກແກ້ວ",
            coordinates: [102.630116, 17.904717]
          },
          {
            code: "0107012",
            villageName: "ບ້ານຫົວຫ້າ",
            coordinates: [102.630116, 17.904717]
          },
          {
            code: "0107013",
            villageName: "ບ້ານຫາດກັນຊາ",
            coordinates: [102.630116, 17.904717]
          },
          {
            code: "0107014",
            villageName: "ບ້ານດອນ",
            coordinates: [102.105718, 18.770857]
          },
          {
            code: "0107015",
            villageName: "ບ້ານໂພເງິນ",
            coordinates: [102.62981414794922, 17.843404444115325]
          },
          {
            code: "0107016",
            villageName: "ບ້ານໂພສີ",
            coordinates: [102.63818264007568, 17.837685210756863]
          },
          {
            code: "0107017",
            villageName: "ບ້ານສະຫວ່າງ",
            coordinates: [102.600965, 18.043899]
          },
          {
            code: "0107018",
            villageName: "ບ້ານປາຝາງ",
            coordinates: [102.630116, 17.904717]
          },
          {
            code: "0107019",
            villageName: "ບ້ານຈອມທອງ",
            coordinates: [102.630116, 17.904717]
          },
          {
            code: "0107020",
            villageName: "ບ້ານຫ້ອມໃຕ້",
            coordinates: [102.64060735702515, 17.836643473472336]
          },
          {
            code: "0107021",
            villageName: "ບ້ານຈຽມປາງ",
            coordinates: [102.6454246044159, 17.835346399903752]
          },
          {
            code: "0107022",
            villageName: "ບ້ານຖິ່ນເພັຍ",
            coordinates: [102.630116, 17.904717]
          },
          {
            code: "0107023",
            villageName: "ບ້ານທ່າແຂກ",
            coordinates: [102.66551971435545, 17.816522450344547]
          },
          {
            code: "0107024",
            villageName: "ບ້ານຊາຍຟອງເໜືອ",
            coordinates: [102.630116, 17.904717]
          },
          {
            code: "0107025",
            villageName: "ບ້ານຊາຍຟອງໃຕ້",
            coordinates: [102.630116, 17.904717]
          },
          {
            code: "0107026",
            villageName: "ບ້ານໂຄກຊາຍ",
            coordinates: [102.68637657165527, 17.82542909214264]
          },
          {
            code: "0107027",
            villageName: "ບ້ານສີຖານໃຕ້",
            coordinates: [102.6862907409668, 17.8143978645438]
          },
          {
            code: "0107028",
            villageName: "ບ້ານຫາດຊາຍຂາວ",
            coordinates: [102.60034, 18.030511]
          },
          {
            code: "0107029",
            villageName: "ບ້ານສົມຫວັງເໜືອ",
            coordinates: [102.630116, 17.904717]
          },
          {
            code: "0107030",
            villageName: "ບ້ານໜອງໄຮ",
            coordinates: [102.64183044433592, 17.904588727995474]
          },
          {
            code: "0107031",
            villageName: "ບ້ານສົມສະໜຸກ",
            coordinates: [102.579911, 18.063999]
          },
          {
            code: "0107032",
            villageName: "ບ້ານໜອງແວງ",
            coordinates: [102.630116, 17.904717]
          },
          {
            code: "0107033",
            villageName: "ບ້ານສາລາຄຳເໜືອ",
            coordinates: [102.64389038085938, 17.883025510762106]
          },
          {
            code: "0107034",
            villageName: "ບ້ານນາໄຫ",
            coordinates: [102.66672134399414, 17.872651363309565]
          },
          {
            code: "0107035",
            villageName: "ບ້ານດົງຄຳຊ້າງ",
            coordinates: [102.66260147094727, 17.90385366146399]
          },
          {
            code: "0107036",
            villageName: "ບ້ານນາໄຮ່",
            coordinates: [102.64225959777832, 17.904588727995474]
          },
          {
            code: "0107037",
            villageName: "ບ້ານໜອງແຫ້ວ",
            coordinates: [102.68929481506348, 17.877879355868426]
          },
          {
            code: "0107038",
            villageName: "ບ້ານດົງໂພສີ",
            coordinates: [102.725278735316, 17.888906648023507]
          },
          {
            code: "0107039",
            villageName: "ບ້ານທ່ານາແລ້ງ",
            coordinates: [102.72525787353516, 17.888906648023507]
          },
          {
            code: "0107040",
            villageName: "ບ້ານທ່າມ່ວງ",
            coordinates: [102.630116, 17.904717]
          },
          {
            code: "0107041",
            villageName: "ບ້ານດົງໂພນແຮ່",
            coordinates: [102.630116, 17.904717]
          },
          {
            code: "0107042",
            villageName: "ບ້ານປ່າຫວ້າ",
            coordinates: [102.630116, 17.904717]
          },
          {
            code: "0107043",
            villageName: "ບ້ານທ່າເດື່ອ",
            coordinates: [102.743767, 17.893452]
          },
          {
            code: "0107044",
            villageName: "ບ້ານຖິ່ນຕົມ",
            coordinates: [102.630116, 17.904717]
          },
          {
            code: "0107045",
            villageName: "ບ້ານຄຳຈະເລີນ",
            coordinates: [102.74585723876953, 17.919779420684304]
          },
          {
            code: "0107046",
            villageName: "ບ້ານໜອງແປນເໜືອ",
            coordinates: [102.74602890014648, 17.929579176533604]
          },
          {
            code: "0107047",
            villageName: "ບ້ານຊຽງຄວນ",
            coordinates: [102.761387, 17.907474]
          },
          {
            code: "0107048",
            villageName: "ບ້ານໜອງແປນໃຕ້",
            coordinates: [102.74602890014648, 17.929579176533604]
          },
          {
            code: "0107049",
            villageName: "ບ້ານທ່າພະ",
            coordinates: [102.7796745300293, 17.935785408097484]
          },
          {
            code: "0107050",
            villageName: "ບ້ານປາກເປັ່ງ",
            coordinates: [102.78928756713867, 17.93954170571153]
          },
          {
            code: "0107051",
            villageName: "ບ້ານໜອງພົງ",
            coordinates: [102.74642586708069, 17.968915651783703]
          },
          {
            code: "0107052",
            villageName: "ບ້ານຖິ່ນແທ່ນ",
            coordinates: [102.79752731323242, 17.944277793412617]
          },
          {
            code: "0107053",
            villageName: "ບ້ານຄວາຍແດງ",
            coordinates: [102.82567977905272, 17.95570920685117]
          },
          {
            code: "0107054",
            villageName: "ບ້ານສິມມະໂນເໜືອ",
            coordinates: [102.84151554107666, 17.968731952208923]
          },
          {
            code: "0107055",
            villageName: "ບ້ານສິມມະໂນໃຕ້",
            coordinates: [102.84151554107666, 17.968731952208923]
          },
          {
            code: "0107056",
            villageName: "ບ້ານນາລ້ອງ",
            coordinates: [102.8895378112793, 17.983957957423037]
          },
          {
            code: "0107057",
            villageName: "ບ້ານດອນຝ້າຍ",
            coordinates: [102.630116, 17.904717]
          },
          {
            code: "0107058",
            villageName: "ບ້ານສາລາຄຳໃຕ້",
            coordinates: [102.65341758728027, 17.874693566213658]
          },
          {
            code: "0107059",
            villageName: "ບ້ານຖິ່ນແທ່ນ",
            coordinates: [102.79855728149414, 17.943297923599122]
          }
        ]
      }
    ]
  },
  {
    code: "02",
    province_name: "ຜົ້ງສາລີ",
    district_list: [
      {
        code: "0203",
        district: "ຂວາ"
      },
      {
        code: "0204",
        district: "ສຳພັນ"
      },
      {
        code: "0206",
        district: "ຍອດອູ"
      },
      {
        code: "0207",
        district: "ບຸນໃຕ້"
      },
      {
        code: "0205",
        district: "ບຸນເໜືອ"
      },
      {
        code: "0201",
        district: "ຜົ້ງສາລີ"
      },
      {
        code: "0202",
        district: "ໃໝ່"
      }
    ]
  },
  {
    code: "03",
    province_name: "ຫຼວງນ້ຳທາ",
    district_list: [
      {
        code: "0302",
        district: "ສິງ"
      },
      {
        code: "0305",
        district: "ນາແລ"
      },
      {
        code: "0303",
        district: "ລອງ"
      },
      {
        code: "0304",
        district: "ວຽງພູຄາ"
      },
      {
        code: "0301",
        district: "ຫຼວງນ້ຳທາ"
      }
    ]
  },
  {
    code: "04",
    province_name: "ອຸດົມໄຊ",
    district_list: [
      {
        code: "0404",
        district: "ງາ"
      },
      {
        code: "0401",
        district: "ໄຊ"
      },
      {
        code: "0403",
        district: "ນາໝໍ້"
      },
      {
        code: "0405",
        district: "ແບງ"
      },
      {
        code: "0407",
        district: "ປາກແບງ"
      },
      {
        code: "0406",
        district: "ຮຸນ"
      },
      {
        code: "0402",
        district: "ຫຼາ"
      }
    ]
  },
  {
    code: "05",
    province_name: "ບໍ່ແກ້ວ",
    district_list: [
      {
        code: "0502",
        district: "ຕົ້ນເຜິ້ງ"
      },
      {
        code: "0505",
        district: "ປາກທາ"
      },
      {
        code: "0504",
        district: "ຜາອຸດົມ"
      },
      {
        code: "0503",
        district: "ເມິງ"
      },
      {
        code: "0501",
        district: "ຫ້ວຍຊາຍ"
      }
    ]
  },
  {
    code: "06",
    province_name: "ຫຼວງພະບາງ",
    district_list: [
      {
        code: "0606",
        district: "ງອຍ"
      },
      {
        code: "0609",
        district: "ຈອມເພັດ"
      },
      {
        code: "0602",
        district: "ຊຽງເງິນ"
      },
      {
        code: "0603",
        district: "ນານ"
      },
      {
        code: "0605",
        district: "ນ້ຳບາກ"
      },
      {
        code: "0607",
        district: "ປາກແຊງ"
      },
      {
        code: "0604",
        district: "ປາກອູ"
      },

      {
        code: "0608",
        district: "ໂພນໄຊ"
      },
      {
        code: "0611",
        district: "ພູຄູນ"
      },
      {
        code: "0612",
        district: "ໂພນທອງ"
      },
      {
        code: "0610",
        district: "ວຽງຄຳ"
      },
      {
        code: "0601",
        district: "ນະຄອນ ຫຼວງພະບາງ"
      }
    ]
  },
  {
    code: "07",
    province_name: "ຫົວພັນ",
    district_list: [
      {
        code: "0709",
        district: "ກວັນ"
      },
      {
        code: "0707",
        district: "ສົບເບົາ"
      },
      {
        code: "0710",
        district: "ຊ່ອນ"
      },
      {
        code: "0702",
        district: "ຊຽງຄໍ້"
      },
      {
        code: "0706",
        district: "ຊຳໃຕ້"
      },
      {
        code: "0701",
        district: "ຊຳເໜືອ"
      },
      {
        code: "0704",
        district: "ວຽງໄຊ"
      },
      {
        code: "0705",
        district: "ຫົວເມືອງ"
      },
      {
        code: "0708",
        district: "ແອດ"
      },
      {
        code: "0703",
        district: "ຮ້ຽມ"
      },
      {
        code: "0711",
        district: "ວຽງທອງ"
      },
    ]
  },
  {
    code: "08",
    province_name: "ໄຊຍະບູລີ",
    district_list: [
      {
        code: "0808",
        district: "ແກ່ນທ້າວ"
      },
      {
        code: "0802",
        district: "ຄອບ"
      },
      {
        code: "0804",
        district: "ເງິນ"
      },
      {
        code: "0801",
        district: "ໄຊຍະບູລີ"
      },
      {
        code: "0811",
        district: "ໄຊຊະຖານ"
      },
      {
        code: "0805",
        district: "ຊຽງຮ່ອນ"
      },
      {
        code: "0810",
        district: "ທົ່ງມີໄຊ"
      },
      {
        code: "0809",
        district: "ບໍ່ແຕນ"
      },
      {
        code: "0807",
        district: "ປາກລາຍ"
      },
      {
        code: "0806",
        district: "ພຽງ"
      },
      {
        code: "0803",
        district: "ຫົງສາ"
      }
    ]
  },
  {
    code: "09",
    province_name: "ຊຽງຂວາງ",
    district_list: [
      {
        code: "0902",
        district: "ຄຳ"
      },
      {
        code: "0904",
        district: "ຄູນ"
      },
      {
        code: "0901",
        district: "ແປກ"
      },
      {
        code: "0907",
        district: "ຜາໄຊ"
      },
      {
        code: "0906",
        district: "ພູກູດ"
      },
      {
        code: "0903",
        district: "ໜອງແຮດ"
      },
      {
        code: "0905",
        district: "ໝອກ"
      }
    ]
  },
  {
    code: "10",
    province_name: "ວຽງຈັນ",
    district_list: [
      {
        code: "1004",
        district: "ກາສີ"
      },
      {
        code: "1003",
        district: "ແກ້ວອຸດົມ"
      },
      {
        code: "1007",
        district: "ຊະນະຄາມ"
      },
      {
        code: "1002",
        district: "ທຸລະຄົມ"
      },
      {
        code: "1001",
        district: "ໂພນໂຮງ"
      },
      {
        code: "1006",
        district: "ເຟືອງ"
      },
      {
        code: "1005",
        district: "ວັງວຽງ"
      },
      {
        code: "1008",
        district: "ແມດ"
      },
      {
        code: "1009",
        district: "ວຽງຄຳ"
      },
      {
        code: "1010",
        district: "ຫີນເຫີບ"
      },
      {
        code: "1011",
        district: "ໝື່ນ"
      }
    ]
  },
  {
    code: "11",
    province_name: "ບໍລິຄຳໄຊ",
    district_list: [
      {
        code: "1105",
        district: "ຄຳເກີດ"
      },
      {
        code: "1107",
        district: "ໄຊຈຳພອນ"
      },
      {
        code: "1102",
        district: "ທ່າພະບາດ"
      },
      {
        code: "1104",
        district: "ບໍລິຄັນ"
      },
      {
        code: "1103",
        district: "ປາກກະດິງ"
      },
      {
        code: "1101",
        district: "ປາກຊັນ"
      },
      {
        code: "1106",
        district: "ວຽງທອງ"
      }
    ]
  },
  {
    code: "12",
    province_name: "ຄຳມ່ວນ",
    district_list: [
      {
        code: "1210",
        district: "ຄູນຄຳ"
      },
      {
        code: "1209",
        district: "ໄຊບົວທອງ"
      },
      {
        code: "1208",
        district: "ເຊບັ້ງໄຟ"
      },
      {
        code: "1205",
        district: "ຍົມມະລາດ"
      },
      {
        code: "1201",
        district: "ທ່າແຂກ"
      },
      {
        code: "1207",
        district: "ນາກາຍ"
      },
      {
        code: "1206",
        district: "ບົວລະພາ"
      },
      {
        code: "1202",
        district: "ມະຫາໄຊ"
      },
      {
        code: "1204",
        district: "ຫີນບູນ"
      },
      {
        code: "1203",
        district: "ໜອງບົກ"
      }
    ]
  },
  {
    code: "13",
    province_name: "ສະຫວັນນະເຂດ",
    district_list: [
      {
        code: "1301",
        district: "ນະຄອນໄກສອນ ພົມວິຫານ"
      },
      {
        code: "1309",
        district: "ຈຳພອນ"
      },
      {
        code: "1308",
        district: "ສອງຄອນ"
      },
      {
        code: "1306",
        district: "ນອງ"
      },
      {
        code: "1307",
        district: "ທ່າປາງທອງ"
      },
      {
        code: "1305",
        district: "ເຊໂປນ"
      },
      {
        code: "1314",
        district: "ໄຊພູທອງ"
      },
      {
        code: "1311",
        district: "ໄຊບູລີ"
      },
      {
        code: "1310",
        district: "ຊົນນະບູລີ"
      },
      {
        code: "1304",
        district: "ພິນ"
      },
      {
        code: "1315",
        district: "ພະລານໄຊ"
      },
      {
        code: "1312",
        district: "ວິລະບູລີ"
      },
      {
        code: "1313",
        district: "ອາດສະພອນ"
      },
      {
        code: "1303",
        district: "ອາດສະພັງທອງ"
      },
      {
        code: "1302",
        district: "ອຸທຸມພອນ"
      }
    ]
  },
  {
    code: "14",
    province_name: "ສາລະວັນ",
    district_list: [
      {
        code: "1406",
        district: "ຄົງເຊໂດນ"
      },
      {
        code: "1408",
        district: "ສະໝ້ວຍ"
      },
      {
        code: "1401",
        district: "ສາລະວັນ"
      },
      {
        code: "1402",
        district: "ຕາໂອ້ຍ"
      },
      {
        code: "1403",
        district: "ຕຸ້ມລານ"
      },
      {
        code: "1404",
        district: "ລະຄອນເພັງ"
      },
      {
        code: "1407",
        district: "ເລົ່າງາມ"
      },
      {
        code: "1405",
        district: "ວາປີ"
      }
    ]
  },
  {
    code: "15",
    province_name: "ເຊກອງ",
    district_list: [
      {
        code: "1502",
        district: "ກະລຶມ"
      },
      {
        code: "1503",
        district: "ດາກຈຶງ"
      },
      {
        code: "1504",
        district: "ທ່າແຕງ"
      },
      {
        code: "1501",
        district: "ລະມາມ"
      }
    ]
  },
  {
    code: "16",
    province_name: "ຈຳປາສັກ",
    district_list: [
      {
        code: "1610",
        district: "ໂຂງ"
      },
      {
        code: "1607",
        district: "ຈຳປາສັກ"
      },
      {
        code: "1608",
        district: "ສຸຂຸມາ"
      },
      {
        code: "1602",
        district: "ຊະນະສົມບູນ"
      },
      {
        code: "1603",
        district: "ບາຈຽງຈະເລີນສຸກ"
      },
      {
        code: "1601",
        district: "ນະຄອນປາກເຊ"
      },
      {
        code: "1604",
        district: "ປາກຊ່ອງ"
      },
      {
        code: "1605",
        district: "ປະທຸມພອນ"
      },
      {
        code: "1606",
        district: "ໂພນທອງ"
      },

      {
        code: "1609",
        district: "ມຸນລະປະໂມກ"
      }
    ]
  },
  {
    code: "17",
    province_name: "ອັດຕະປື",
    district_list: [
      {
        code: "1702",
        district: "ສາມັກຄີໄຊ"
      },
      {
        code: "1704",
        district: "ສານໄຊ"
      },
      {
        code: "1703",
        district: "ສະໜາມໄຊ"
      },
      {
        code: "1701",
        district: "ໄຊເຊດຖາ"
      },
      {
        code: "1705",
        district: "ພູວົງ"
      }
    ]
  },
  {
    code: "18",
    province_name: "ໄຊສົມບູນ",
    district_list: [
      {
        code: "1802",
        district: "ທ່າໂທມ"
      },
      {
        code: "1803",
        district: "ລ້ອງແຈ້ງ"
      },
      {
        code: "1805",
        district: "ລ້ອງຊານ"
      },
      {
        code: "1801",
        district: "ອານຸວົງ"
      },
      {
        code: "1804",
        district: "ຮົ່ມ"
      }
    ]
  }
];
