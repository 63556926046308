import React from 'react'
import { Col, Row, } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { faImage, faQrcode } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function PayThroughSLMS() {
	return (
		<div>
			<div className='container' style={{ height: '100vh' }}>
				<div style={{ backgroundColor: '#fff', padding: 40 }}>
					<h2 style={{ fontWeight: "bold", color: "#6F6F6F" }}>ຊ່ອງ​ທາງ​ການ​ຊຳ​ລະ​ຄ່າ​ຮຽນ</h2>
					<Col className='text-center' style={{ marginBottom: 40, marginTop: 50 }}>
						<p style={{ fontSize: 29, fontWeight: "bold", color: "#707070" }}>ຊ່ອງ​ທາງ​​ຊຳ​ລະ​ດ້ວຍ​ເງິນ​ສົດ​ໂດຍ​ຜ່ານສູນ SLMS</p>
						<h2 style={{ fontSize: 24 }}>
							ສຳລັບ​ການ​ຊຳ​ລະ​ຄ່າ​ຮຽນ​ດ້ວຍ​ເງິນ​ສົດ​ໂດຍ​ຜ່ານ​ສູນ SLMS
							ສາມ​າດ​ເຮັດ​ໄດ້​ງ່າຍຜ່ານ​ຂັ້ນ​ຕອນ​ດັ່ງ​ຕໍ່​ໄປ​ນີ້:
						</h2>
					</Col>
					<Row>
						<Col sm='9' style={{ fontSize: 20 }}>
							<p>
								1. ປະກອບເອກະສານໃຫ້​ຄົບ​ຖ້ວນພາຍໃນຊອງ
								ແລ້ວ​ນຳເອົາໄປຍື່ນ​ຢູ່ບູດ​ຂອງສຸນ SLMS.
							</p>
							<p>
								2.
								ກຽມ​ເງິນ​ຄ່າ​ຮຽນ​ໃຫ້​ຄົບ​ຖ້ວນ​ເພື່ອ​ນຳ​ໄປ​ຊຳ​ລະ​ຄ່າ​ຮຽນ​ນຳ​ທາງ
								SLMS.
							</p>
							<p>
								3. ທາງ SLMS ຈະເຂົ້າເວັບໄຊເພື່ອ Login ແລະ
								ຈະປ້ອນ​ຂໍ້​ມູນໃຫ້​ນັກ​ສຶກ​ສາ​.
							</p>
							<p>
								4. ພາຍຫຼັງກົດລົງທະບຽນແລ້ວ ທາງສູນ SLMS
								ຈະແຈ້ງ​ນັກ​ສຶ​ກ​ສາ​ວ່າ​ໄດ້​ຊຳ​ລະ​ຄ່າ​ຮຽນ​ແລ້ວ ​ແລະ ຈະແນະນຳ
								ໃຫ້ນັກສຶກສາໄປສະເໜີໂຕ​ໂດຍ​ມີເອກະສານໄປຢັ້ງຢືນ (ເອ​ກະ​ສານ​ໃນ​ຊອງ)
								ເພື່ອໄປ​ຈ້ຳ​ກາ​ໃສ່​ບັດ​ນັກ​ສຶກ​ສາ​ຊົ່ວ​ຄາວ​ນຳ​ທາງ ວິ​ຊາ​ການ
							</p>
						</Col>
						<Col sm='3'>
							<div style={{
								color: "#fff",
								backgroundColor: "#777777",
								height: "120%",
								borderRadius: 3,
								justifyContent: "center",
								alignItems: "center",
								display: "flex",
								flexFlow: "column"
							}}>
								<FontAwesomeIcon icon={faImage} size="10x" />
							</div>
						</Col>
						<Col sm="9" style={{ marginTop: 60, fontSize: 20 }}>
							<p style={{ fontWeight: "bold" }}>ຂໍ້​ມູນ​ການ​ຕິດ​ຕໍ່</p>
							<p>ເບີ​ໂທ​ຕິດ​ຕໍ່: 021 771 989</p>
							<p>ອີ​ເມວ: info.01slms@gmail.com</p>
						</Col>
						<Col sm="3" style={{
							justifyContent: "center",
							alignItems: "center",
							display: "flex",
							flexFlow: "column",
							marginTop: 60
						}}>
							<FontAwesomeIcon icon={faQrcode} size="8x" />
						</Col>
					</Row>
				</div>
			</div>
		</div>
	)
}
