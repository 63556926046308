export const VERSION = "v1.0.20"
export const PRIMARY_COLOR = '#057CAE'
export const URL_IMAGE = "https://slms-nuol-file-bucket.s3.amazonaws.com/pregistration-image/"
export const URL_IMAGE_SMALL = "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/resized/small/"
export const URL_IMAGE_MEDIUM = "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/resized/medium/"
export const USER_KEY = 'USER'
export const USER_DATA_KEY = 'USER_DATA'
export const CONTAINER_PADDING = 24
export const CONTAINER_MARGIN_TOP = -10
export const FONT_COLOR_PRIMARY= '#383838'
export const FONT_COLOR_SECONDARY= '#6f6f6f'
export const BORDER_COLOR= '#7BB500'
export const BORDER_COLOR_DELETE= '#B80000'
export const DELETE_COLOR_BUTTON= '#C7C7C7'
