import React from 'react'
import { Col, Row, Card } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { faChevronLeft, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useReactRouter from 'use-react-router';


export default function QRcodePresentAtemporaryStudentCard() {
	const { history } = useReactRouter();
	const styles = {
		border: '1px solid rgba(0, 0, 0, 0.05)',
		width: 196,
		height: 197,
		padding: 10
	};
	const bordered = {
		border: '2px solid rgba(0, 0, 0, 0.10)',
		width: 225,
		height: 64,
		padding: 10,
		fontSize: 15,
		borderRadius: 5,
	};
	return (
		<div className="container" style={{ height: "100vh" }}>
			<Card>
				<Card.Body>
					<FontAwesomeIcon color={"#057CAE"} icon={faChevronLeft} size="3x"
						style={{ cursor: "pointer" }} onClick={() => history.goBack()} />
					<span style={{ marginLeft: 60, fontSize: 30, color: "#6F6F6F", fontWeight: "bold" }}>ບັດ​ປ​ະ​ຈຳ​ຕົວນັກ​ສຶກ​ສາ​ຊົ່ວ​ຄາວ</span>
					<Col style={{ display: "flex", justifyContent: "center", marginTop: 43, marginBottom: 54 }}>
						<Card style={{ width: 484, height: 580 }}>
							<Card.Body >
								<Col style={{ alignItems: "center", justifyContent: "center", textAlign: "center", display: "flex" }}>
									<div className="card-profile"
										style={{
											flexDirection: "column",
											justifyContent: "space-around",
											display: "flex",
											alignItems: "center",
										}}>
										<div style={{ borderRadius: 110, backgroundColor: "#888888", paddingLeft: 20, paddingRight: 20, paddingTop: 15, paddingBottom: 15 }}>
											<FontAwesomeIcon icon={faUser} size="5x" color={"#ffff"} />
										</div>
									</div>
								</Col>
								<Row style={{ paddingLeft: 63, marginTop: 24 }} >
									<Col sm="6">ຊື່</Col>
									<Col sm="6">ໂບ່</Col>
									<Col sm="6">ນາມສະກຸນ</Col>
									<Col sm="6">dfs</Col>
									<Col sm="6">ລະ​ຫັດ​ນັກ​ສຶກ​ສາ</Col>
									<Col sm="6">dfs</Col>
								</Row>
								<Col style={{ textAlign: "center", justifyContent: "center", display: "flex", marginTop: 24 }}>
									<Col sm="12" style={styles} > Qr code </Col>
								</Col>
								<Col className="d-flex justify-content-between" style={{ padding: 10 }}>
									<Col style={bordered}>
										ໝາຍ​ເຫດ: ກຳ​ນົດ​ໃຊ​້​ບັດ 90 ວັນ ນັບ​ແຕ່​ວັນ​ທີ່​ອອກ​ບັດ​ເປັນ​ຕົ້ນ​ໄປ
									</Col>
									<Col style={{ textAlign: "right", fontSize: 12, marginTop: 45 }}>
										ວັນ​ທີ່​ລົງ​ທະ​ບຽນ​ອອນ​ລາຍ: 06/05/2021
									</Col>
								</Col>
							</Card.Body>
						</Card>
					</Col>
				</Card.Body>
			</Card>
		</div>
	)
}
