import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '../../images/logo-nuol.png'
import { Formik } from 'formik'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import { BrowserView, MobileView } from 'react-device-detect'
import useReactRouter from 'use-react-router'
import { USER_DATA_KEY, USER_KEY } from '../../consts/index'
import { LOGIN } from '../../apollo/studentAuth'
import pngkit_call from '../../images/pngkit_call.png'
import { Button, Form, Row, Col, Modal } from 'react-bootstrap'
import Loading from '../../common/Loading'
import {
	faSignInAlt,
	faUser
} from '@fortawesome/free-solid-svg-icons'
import { STUDENTS } from '../../apollo/students'
import _ from 'lodash'

function Form_login() {
	const { history } = useReactRouter()
	// const studentTypeForm = match.params.studentType
	const [studentTypeForm, setStudentTypeForm] = useState('')
	const [errorMessage, setErrorMessage] = useState('')
	const [errorStudentType, setErrorStudentType] = useState(false)
	const [showDanger, setShowDanger] = useState(false)
	const [showModalMobile, setShowModalMobile] = useState(false)
	const [code, setCode] = useState()
	const [assessmentYear, setAssessmentYear] = useState("")
	const [isLoading, setIsLoading] = useState(false)
	const [login] = useMutation(LOGIN)
	const [loadDataStudent, { data: dataStudent }] = useLazyQuery(STUDENTS, { fetchPolicy: "network-only" })

	useEffect(() => {
		const NOW = new Date().getFullYear();
		const checkMonth = new Date().getMonth() + 1
		let year
		if (checkMonth === 8 || checkMonth === 9 || checkMonth === 10 || checkMonth === 11 || checkMonth === 12) {
			year = NOW + " - " + (parseInt(NOW) + 1);
		} else {
			year = (NOW - 1) + " - " + NOW;
		}
		setAssessmentYear(year)
		// setAssessmentYear("2024 - 2025")
	}, [])
	useEffect(() => {
		const fetch = async () => {
			if (dataStudent?.students) {
				if (dataStudent?.students?.data?.length > 0) {
					if (studentTypeForm === "OUT_PLAN" || studentTypeForm === "IN_PLAN") {
						await localStorage.setItem(
							USER_DATA_KEY,
							JSON.stringify(dataStudent?.students?.data[0]?.studentType)
						)
						if (dataStudent?.students?.data[0]?.statusStep == 'SAVED') {
							history.push(`/registrationStudent`, { studentType: dataStudent?.students?.data[0]?.studentType, code: dataStudent?.students?.data[0]?.code })
							setIsLoading(false)
						} else {
							history.push(
								`/cardstudent/${dataStudent?.students?.data[0]?.code}`
							)
							setIsLoading(false)
						}
					} else {
						setIsLoading(false)
						setErrorStudentType(true)
					}
				} else {
					if (studentTypeForm === "OUT_PLAN" || studentTypeForm === "IN_PLAN") {
						history.push(`/registrationStudent`, { studentType: studentTypeForm, code: code })
						setIsLoading(false)
					} else {
						setIsLoading(false)
						setErrorStudentType(true)
					}
				}
			}
		}
		fetch()
	}, [dataStudent])

	const _login = async values => {
		try {
			setIsLoading(true)
			const codeInput = _.split(code, '/')
			const responseData = await login({
				variables: {
					data: {
						code: code
					}
				}
			})
			const data = responseData?.data?.login
			setStudentTypeForm(responseData?.data?.login?.studentType)
			await localStorage.setItem(USER_KEY, JSON.stringify(data))
			await loadDataStudent({
				variables: {
					where: { code: codeInput[0], assessmentYear: assessmentYear }
				}
			})
		} catch (error) {
			setIsLoading(false)
			setErrorMessage(error.message)
		}
	}

	const _onEnterKey = async (e) => {
		if (e.key == 'Enter') {
			e.preventDefault()
			try {
				setIsLoading(true)
				const codeInput = _.split(code, '/')
				const responseData = await login({
					variables: {
						data: {
							code: code
						}
					}
				})
				const data = responseData?.data?.login
				setStudentTypeForm(responseData?.data?.login?.studentType)
				await localStorage.setItem(USER_KEY, JSON.stringify(data))
				await loadDataStudent({
					variables: {
						where: { code: codeInput[0], assessmentYear: assessmentYear }
					}
				})
			} catch (error) {
				setIsLoading(false)
				setErrorMessage(error.message)
			}
		}
	}

	const _handleChangeCode = async (e) => {
		setCode(e.target.value);
	}
	if (isLoading) return <Loading />
	return (
		<div style={{ background: '#F5F5F5', height: '100vh' }}>
			<BrowserView>
				<div className='d-flex justify-content-between'>
					<div></div>
					<div style={{ marginLeft: 250 }}>
						<img className='logo' src={logo} alt='logo' />
					</div>
					<div
						className='textfont-size20'
						style={{ paddingRight: 24, paddingTop: 24 }}
					>
						ມີ​ບັນ​ຫາ​ເຂົ້າ​ສູ່​ລະ​ບົບ?{' '}
						<a href='#' onClick={() => setShowDanger(true)} >
							ຊ່ວຍ​ເຫຼືອ
						</a>
					</div>
				</div>
				<div className='textfont-size24 text-center' style={{ marginBottom: 41 }}>
					ມະຫາວິທະຍາໄລແຫ່ງຊາດ
				</div>
				<div className='d-flex justify-content-center'>
					<div
						style={{
							backgroundColor: '#fff',
							padding: 30,
							paddingLeft: 80,
							paddingRight: 80,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<h2>
							<b>ກະລຸນາລ໊ອກອິນເພື່ອເຂົ້າໃຊ້ລະບົບ</b>
						</h2>

						<Form
							noValidate
							style={{
								width: '100%',
								paddingTop: 24,
								justifyContent: 'center',
							}}
						>
							<Form.Group as={Row} controlId='formPlaintextEmail'>
								<Form.Label
									column
									sm='2'
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-end',
										marginTop: -5
									}}
								>
									<FontAwesomeIcon icon={faUser} size='2x' color='#057CAE' />
								</Form.Label>
								<Col sm='8' style={{ marginBottom: 27 }}>
									<Form.Control
										name='code'
										type='text'
										placeholder='ລະ​ຫັດ​ທີ່​ໄດ້​ຮັບ​ຈາກຊອງ'
										onChange={(e) => _handleChangeCode(e)}
										onKeyDown={(e) => _onEnterKey(e)}
										value={code}
									/>
									{errorMessage ? (
										<span style={{ color: '#CC0000', fontSize: 14 }}>
											{errorMessage == 'GraphQL error: THE_CODE_CAN_NOT_FOUND'
												? '* ລະ​ຫັດບໍ່ຖືກຕ້ອງ, ກະລຸນາລອງໃໝ່ອີກຄັ້ງ'
												: ''}
											{errorMessage == 'LOGIN_IS_NOT_FORMATTED'
												? '* ລະ​ຫັດທີ່ທ່ານບໍ່ຕົງກັບແບບຟອມນີ້, ກະລຸນາກັບຄືນເພື່ອເລືອກ​ຮູບ​ແບບ​ການ​ເຂົ້າ​ສູ່​ລະ​ບົບ ທີຖືກຕ້ອງ'
												: ''}
										</span>
									) : (
										''
									)}
									{errorStudentType ? (
										<span style={{ color: '#CC0000', fontSize: 14 }}>
											<br />* ລະຫັດນີ້ໃຊ້ບໍ່ໄດ້ກັບແບບຟອມນີ້.<br />
											ກະລຸນາເລືອກແບບຟອມໃຫ້ຖືກຕ້ອງ ແລ້ວລອງໃໝ່ອີກຄັ້ງ.
										</span>
									) : <></>
									}
								</Col>
								<Col sm='2'></Col>
								<Col sm='1'></Col>
								<Col sm='9'>
									<Button
										style={{
											width: '97%',
											backgroundColor: '#057CAE',
											marginTop: 24,
											marginLeft: 15
										}}
										type="button"
										onClick={() => _login()}
									>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'center'
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													width: '100%',
													fontSize: 20
												}}
											>
												ລ໊ອກອິນ
											</div>
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													width: 30,
													alignItems: 'flex-end'
												}}
											>
												<FontAwesomeIcon
													icon={faSignInAlt}
													style={{ fontSize: 24, fontWeight: 'normal' }}
													color='#fff'
												/>
											</div>
										</div>
									</Button>
								</Col>
							</Form.Group>
						</Form>

						<div
							className='textfont-size18 bottomleft'
							style={{ marginTop: 45 }}
						>
							“ໝາຍເຫດ: ລະ​ຫັດ​ ​ແມ່ນທ່ານ​ຈະ​ໄດ້​ຮັບມາຈາກຊອງ​”
							<br />ຕົວຢ່າງ: 115Q0001/23 ຫລື 115N0001/23
						</div>
					</div>
				</div>
			</BrowserView>

			<MobileView>
				<div className='d-flex justify-content-center'>
					<div style={{ textAlign: "center" }}>
						<img width="99px" src={logo} alt='logo mobile screen' />
					</div>
				</div>
				<div style={{ marginBottom: 41, marginTop: 10, fontSize: 12, textAlign: "center" }}>
					ມະຫາວິທະຍາໄລແຫ່ງຊາດ<br />
					National University of Laos

				</div>
				<div style={{ display: "flex", alignItems: "center", flexDirection: "column", backgroundColor: "#F5F5F5" }}>
					<Formik
						initialValues={{
							code: ''
						}}
						onSubmit={async values => {
							_login(values)
						}}
					>
						{({ values, handleSubmit, handleChange }) => (
							<div
								style={{
									width: 300,
									backgroundColor: '#fff',
									padding: 20,
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center'
								}}
							>
								<b>ກະລຸນາລ໊ອກອິນເພື່ອເຂົ້າໃຊ້ລະບົບ</b>

								<Form
									noValidate
									style={{
										justifyContent: 'center',
										marginTop: 12
									}}
								>
									<Form.Group controlId='formPlaintextEmail'>
										<div style={{ display: "flex", flexDirection: "row", }}>
											<div style={{ marginTop: 40, marginRight: 10 }}>
												<FontAwesomeIcon icon={faUser} size='2x' color='#057CAE' />
											</div>
											<div style={{ marginTop: 40 }}>
												<Form.Control
													style={{ fontSize: 14 }}
													name='code'
													type='text'
													placeholder='ລະ​ຫັດ​ທີ່​ໄດ້​ຮັບ​ຈາກຊອງ'
													onChange={(e) => _handleChangeCode(e)}
													onKeyDown={(e) => _onEnterKey(e)}
													value={code}
												/>
												{errorMessage ? (
													<span style={{ color: '#CC0000', fontSize: 10 }}>
														{errorMessage == 'GraphQL error: THE_CODE_CAN_NOT_FOUND'
															? '* ລະ​ຫັດບໍ່ຖືກຕ້ອງ, ກະລຸນາລອງໃໝ່ອີກຄັ້ງ'
															: ''}
														{errorMessage == 'LOGIN_IS_NOT_FORMATTED'
															? '* ລະ​ຫັດທີ່ທ່ານບໍ່ຕົງກັບແບບຟອມນີ້, ກະລຸນາກັບຄືນເພື່ອເລືອກ​ຮູບ​ແບບ​ການ​ເຂົ້າ​ສູ່​ລະ​ບົບ ທີຖືກຕ້ອງ'
															: ''}
													</span>
												) : (
													''
												)}
												{errorStudentType ? (
													<span style={{ color: '#CC0000', fontSize: 14 }}>
														<br />* ລະຫັດນີ້ໃຊ້ບໍ່ໄດ້ກັບແບບຟອມນີ້.<br />
														ກະລຸນາເລືອກແບບຟອມໃຫ້ຖືກຕ້ອງ ແລ້ວລອງໃໝ່ອີກຄັ້ງ.
													</span>
												) : <></>
												}
											</div>
										</div>

										<div>
											<Button
												style={{
													width: '100%',
													backgroundColor: '#057CAE',
													marginTop: 45,
													height: 40
												}}
												onClick={() => handleSubmit()}
											>
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
														justifyContent: 'center'
													}}
												>
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															justifyContent: 'center',
															width: '100%',
															fontSize: 12
														}}
													>
														ລ໊ອກອິນ
													</div>
													<div >
														<FontAwesomeIcon
															icon={faSignInAlt}
															style={{ fontSize: 24, fontWeight: 'normal' }}
															color='#fff'
														/>
													</div>
												</div>
											</Button>
										</div>
									</Form.Group>
								</Form>

								<div style={{ marginTop: 34, fontSize: 12 }} >
									“ໝາຍເຫດ: ລະ​ຫັດ​ ​ແມ່ນທ່ານ​ຈະ​ໄດ້​ຮັບມາຈາກຊອງ​” <br />ຕົວຢ່າງ: 124N331/22
								</div>
							</div>
						)}
					</Formik>
					<div style={{ fontSize: 14, textAlign: "center", marginTop: 75, }}>
						ມີ​ບັນ​ຫາ​ເຂົ້າ​ສູ່​ລະ​ບົບ?{' '}
						<a href='#' onClick={() => setShowModalMobile(true)}>
							ຊ່ວຍ​ເຫຼືອ
						</a>
					</div>
				</div>
				<Modal
					show={showModalMobile}
					onHide={() => setShowModalMobile(false)}
					animation={false}
				>
					<Modal.Header className='borderModalLoginMobile'>
						<div>ຕິດ​ຕໍ່​ພວກ​ເຮົາ</div>
					</Modal.Header>
					<Modal.Body
						style={{
							color: '#707070',
							textAlign: 'center',
							fontSize: 12,
							fontWeight: 'bold'
						}}
					>
						<p>ຊ່ອງ​ທາງ​ໃນ​ການຕິດ​ຕໍ່​​ພວກ​ເຮົາ​</p>
						<p>ເບີ​ໂທ​ລະ​ສັບ: 021 771 989</p>
						<p>ວິຊາການ ມຊ: 020 5445 6359, 020 5252 2266</p>
						<p>ອີ​ເມວ: info.01slms@gmail.com</p>
						<p>ໝາຍເຫດ: ຕິດຕໍ່ໄດ້ພາຍໃນໂມງລາດຊະການເທົ່ານັ້ນ </p>
						<img src={pngkit_call} style={{ width: 120, height: 79 }} alt='mobile screen' />
					</Modal.Body>
				</Modal>
			</MobileView>

			<Modal
				show={showDanger}
				onHide={() => setShowDanger(false)}
				animation={false}
			>
				<Modal.Header className='borderModalLogin'>
					<div>ຕິດ​ຕໍ່​ພວກ​ເຮົາ</div>
				</Modal.Header>
				<Modal.Body
					style={{
						color: '#707070',
						textAlign: 'center',
						fontSize: 20,
						fontWeight: 'bold'
					}}
				>
					<p>ຊ່ອງ​ທາງ​ໃນ​ການຕິດ​ຕໍ່​​ພວກ​ເຮົາ​</p>
					<p>ເບີ​ໂທ​ລະ​ສັບ:</p>
					<p>021 771 989</p>
					<p>ວິຊາການ ມຊ:</p>
					<p>020 5445 6359</p>
					<p>020 5252 2266</p>
					<p>ອີ​ເມວ:</p>
					<p>info.01slms@gmail.com</p>
					<p>ໝາຍເຫດ: ຕິດຕໍ່ໄດ້ພາຍໃນໂມງລາດຊະການເທົ່ານັ້ນ </p>
					<img src={pngkit_call} style={{ width: 190, height: 121 }} alt='desktop screen' />
				</Modal.Body>
			</Modal>
		</div>
	)
}

export default Form_login
