import { gql } from "apollo-boost";

export const LOGIN = gql`
  mutation Login($data: LoginInput!) {
    login(data: $data) {
      id
       code
       lastNameL
       firstNameL
       firstNameE
       lastNameE
       gender
       address{
        province
        district
        village
      }
      faculty{
        id
        name
      }
      department{
        id
        name
      }
      major{
        id
        name
      }
      studentType
      phoneMobile
      birthday
      contactName
      emergencyPhone
      bornAbroad
      educationLevel
      birthAddress{
        country
        province
        district
        village
      }
    }
  }
`;