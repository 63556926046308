import React, { useState, useEffect } from "react";
import { Col, Modal } from "react-bootstrap";
import useReactRouter from "use-react-router";
import CustomButton from "./CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../images/logo-nuol.png";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { USER_KEY, PRIMARY_COLOR, FONT_COLOR_SECONDARY, VERSION } from "../consts/index";

function CustomNavbar() {
  const { history} = useReactRouter();
  const [show, setShow] = useState(false);
  const [studentType, setStudentType] = useState("")
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    if (user && user?.studentType) {
        setStudentType(user?.studentType)
    }
  }, []);

  const _onLogout = () => {
    history.push(`/`);
    localStorage.removeItem(USER_KEY);
  };
  // const _onOpenProfile = () => {
  //     history.push(`/profile-detail`, dataUser)
  // }
  return (
    <div>
      <nav className="navbar fixed-top navbar-light bg-light shadow-sm  mb-0 bg-white rounded">
        <div className="navbar-band text-center" style={{paddingLeft: 20}}>
          <img src={logo} alt="logo" style={{ width: 50, height: 40 }} />
          <p className="title-band">
            {
              studentType === "IN_PLAN" || studentType === "OUT_PLAN"
              ? "ມະຫາວິທະຍາໄລແຫ່ງຊາດ" : "National University of Laos"
            }
          </p>
        </div>
        <span className="navbar-text logout">
          {/* <span>{VERSION}</span> */}
        <span style={{padding: 0, margin: 0, fontSize: 11}}>{VERSION}</span>

          <span 
            onClick={() => setShow(true)} 
            style={{ cursor: 'pointer', color: PRIMARY_COLOR, marginLeft: 32 }} 
            className="textLogout">
              {
                studentType === "IN_PLAN" || studentType === "OUT_PLAN"
                ? "ອອກຈາກລະບົບ" : "Logout"
              }
          </span> &nbsp;
          <FontAwesomeIcon
            icon={faSignOutAlt}
            size="1x"
            color={PRIMARY_COLOR}
            onClick={() => setShow(true)}
            style={{ cursor: 'pointer', marginRight: 16 }}
          />
        </span>
        
      </nav>
      <Modal
        style={{ marginTop: 50 }}
        show={show}
        onHide={() => setShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ color: PRIMARY_COLOR, fontWeight: 'bold' }}>
              {
                studentType === "IN_PLAN" || studentType === "OUT_PLAN"
                ? "ອອກຈາກລະບົບ" : "Logout"
              }
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col
            className='text-center'
            style={{ paddingTop: 40, paddingBottom: 50 }}
          >
            <div style={{ fontWeight: 'bold', fontSize: 24, color: FONT_COLOR_SECONDARY }}>
              {
                studentType === "IN_PLAN" || studentType === "OUT_PLAN"
                ? "ທ່ານຕ້ອງການອອກຈາກລະບົບແທ້ ຫຼື ບໍ?" : "Are you sure?"
              }
            </div>
          </Col>
          <Col className='d-flex justify-content-between'>
            <div></div>
            <CustomButton
              onClick={() => setShow(false)}
              type='submit'
              title={
                studentType === "IN_PLAN" || studentType === "OUT_PLAN"
                ? "ຍົກເລີກ" : "Cancel"
              }
            />

            <CustomButton
              confirm
              onClick={() => _onLogout()}
              type='submit'
              title={
                studentType === "IN_PLAN" || studentType === "OUT_PLAN"
                ? "ຢືນຢັນ" : "Yes"
              }
            />
            <div></div>
          </Col>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default CustomNavbar;
